import { useCoins, useStableCoinIds } from '@hooks/use-coins';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { turbosSdk } from 'src/services/turbos-sdk';
import { Decimal } from 'turbos-clmm-sdk';
import { type FC, memo, useMemo } from 'react';
import styles from './trade-fees.module.scss';
import TradeFeesItem from './trade-fees-item';
import type { RouterCompleteTradeRoute } from 'non-restrictive-aftermath-ts-sdk';
import AftermathTradeFeesItem from './aftermath-trade-fees-item';
import type { PoolSwapRoute } from '@models/pool.model';

interface OwnProps {
  bestRoutes?: BestSwapRouterType[];
  isDeepbook: boolean;
  aftermathRoute: RouterCompleteTradeRoute | undefined;
}

const TradeFees: FC<OwnProps> = ({ bestRoutes, isDeepbook, aftermathRoute }) => {
  const { coins } = useCoins();
  const stableCoins = useStableCoinIds();

  const fees = useMemo(() => {
    if (aftermathRoute) {
      return {
        fees: [aftermathRoute.coinIn, aftermathRoute.coinOut].map((fee) => {
          const coin = coins.find((item) => item.type === fee.type);
          if (!coin) return;

          const totalFee = fee.tradeFee;
          const decimals = coin.decimals;

          return {
            symbol: coin.symbol,
            totalFee,
            feePrice: '',
            decimals,
            type: fee.type,
          };
        }),
        isAftermath: true,
      };
    }

    if (!bestRoutes) return;

    return {
      fees: bestRoutes
        .map(({ swapResult, pool, nextTickIndex, deepbookFee }) => {
          if (!pool || !swapResult) {
            return;
          }
          const a2b = pool.a_to_b;
          const coinA = coins.find((item) => item.type === pool.coin_type_a)!;
          const coinB = coins.find((item) => item.type === pool.coin_type_b)!;
          const currentTick = stableCoins.includes(
            pool.a_to_b ? pool.coin_type_a : pool.coin_type_b,
          )
            ? 0
            : nextTickIndex;
          const currentPrice = turbosSdk.math
            .tickIndexToPrice(
              currentTick,
              coinA.decimals,
              currentTick === 0 ? coinA.decimals : coinB.decimals,
            )
            .toString();
          const decimals = a2b ? coinA.decimals : coinB.decimals;
          const amount = turbosSdk.math.scaleDown(swapResult.fee_amount, decimals);
          const feePrice = new Decimal(currentPrice).mul(amount).toString();

          return {
            symbol: a2b && !isDeepbook ? coinA.symbol : coinB.symbol,
            totalFee: isDeepbook ? deepbookFee : swapResult.fee_amount,
            feePrice,
            decimals,
            pool,
            a2b,
            type: a2b ? coinA.type : coinB.type,
          };
        })
        .filter((res) => res),
      isAftermath: false,
    };
  }, [bestRoutes, coins, stableCoins, isDeepbook]);

  return (
    <>
      <span>Trade Fees</span>
      <div className={styles.wrapper}>
        {fees
          ? fees.isAftermath
            ? fees.fees.map((fee) => {
                return fee ? (
                  <AftermathTradeFeesItem
                    key={fee.symbol}
                    fee={{
                      symbol: fee.symbol,
                      totalFee: fee.totalFee?.toString() ?? '',
                      feePrice: fee.feePrice,
                      decimals: fee.decimals,
                      type: fee.type,
                    }}
                  />
                ) : null;
              })
            : fees.fees.map((fee) => {
                return fee ? (
                  <TradeFeesItem
                    isDeepbook={isDeepbook}
                    key={fee.symbol}
                    fee={
                      fee as {
                        a2b: boolean;
                        symbol: string;
                        totalFee: string | undefined;
                        feePrice: string;
                        decimals: number;
                        pool: PoolSwapRoute;
                      }
                    }
                  />
                ) : null;
              })
          : null}
      </div>
    </>
  );
};

export default memo(TradeFees);
