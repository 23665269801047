import { turbosSdk } from '@libs/turbos-sdk';
import { type TurbosCoinItem } from '@models/coin.model';
import { localCoinModel } from '@models/local-coin.model';
import { useEffect, useState } from 'react';

export const useSearchCoins = (coins: TurbosCoinItem[], searchName: string) => {
  const [searchCoins, setSearchCoins] = useState<TurbosCoinItem[]>([]);
  const getSearchCoins = async () => {
    if (searchName) {
      const searchKey = ['name', 'type', 'symbol'] as (keyof TurbosCoinItem)[];
      const searchCoins = coins.filter((coin) =>
        searchKey.some((item) =>
          (coin[item] as string).toLowerCase().includes(searchName.toLowerCase()),
        ),
      );
      if (
        searchCoins.length < 1 &&
        /^0x([A-Za-z\d]+)::([A-Za-z\d_]+)::([A-Za-z\d_]+)$/.test(searchName)
      ) {
        const coinMetadata = await turbosSdk.provider.getCoinMetadata({ coinType: searchName });
        const newCoin = {
          id: Date.now(),
          name: coinMetadata!.name,
          type: searchName,
          symbol: coinMetadata!.symbol,
          decimals: coinMetadata!.decimals,
          logo_url: coinMetadata!.iconUrl!,
          coingecko_id: '',
          in_quote_list: false,
          is_stable: false,
          in_pool: false,
          is_popular: false,
          is_hot: false,
          category_id: 1,
          faucet_amount: '',
          created_at: new Date(),
          updated_at: new Date(),
          category: {
            badge_url: '',
            id: 1,
            name: '',
          },
          pyth_id: '',
          flag: 0,
          cross_chain: null,
        } satisfies TurbosCoinItem;
        setSearchCoins([newCoin]);
        localCoinModel.append(newCoin);
      } else {
        setSearchCoins(searchCoins);
      }
    } else {
      setSearchCoins(coins);
    }
  };

  useEffect(() => {
    getSearchCoins();
  }, [coins, searchName]);

  return {
    searchCoins,
  };
};
