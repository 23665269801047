import { turbosSdk } from '@libs/turbos-sdk';
import { poolModel, type TurbosPoolItem } from '@models/pool.model';
import { unstable_getObjectFields } from 'turbos-clmm-sdk';
import { useLoading, useModel } from 'foca';
import { useEffect, useMemo, useState } from 'react';
import { BN } from 'turbos-clmm-sdk';
import { useRefreshFlag } from './use-refresh';

interface PoolsOptions {
  orderBy?: string;
  category?: string;
  symbol?: string;
  includeRisk?: boolean;
  pageSize?: number;
}

export const usePools = (key: string, options?: PoolsOptions) => {
  const pools = useModel(poolModel, (state) => state.array[key] || []);
  const loading = useLoading(poolModel.getPools.room, key);
  const { refresh } = useRefreshFlag();

  useEffect(() => {
    poolModel.getPools
      .room(key)
      .execute({ orderBy: '', category: '', symbol: '', includeRisk: false, ...options }, key);
  }, [refresh]);

  return { pools, loading };
};

export const usePoolsByCondition = (
  orderBy: string,
  category: string,
  symbol: string,
  risk: boolean,
  page: number,
  pageSize: number = 100,
) => {
  const key = 'conditional';
  const pools = useModel(poolModel, (state) => ({
    list: state.array[key] || [],
    total: state.total,
    page: state.page,
    pageSize: state.pageSize,
  }));
  const loading = useLoading(poolModel.getPools.room, key);
  const { refresh } = useRefreshFlag();

  useEffect(() => {
    poolModel.getPools
      .room(key)
      .execute({ orderBy, category, symbol, includeRisk: risk, page, pageSize }, key);
  }, [refresh, orderBy, category, page, risk, symbol]);

  return {
    pools: pools.list,
    total: pools.total,
    loading,
    page: pools.page,
    pageSize: pools.pageSize,
  };
};

export const usePoolByIds = (poolIds: string[], key: string) => {
  const pools = useModel(poolModel, (state) => state.array[key] || []);
  const loading = useLoading(poolModel.getPoolByIds.room, key);
  const { refresh } = useRefreshFlag();

  useEffect(() => {
    poolIds.length > 0 && poolModel.getPoolByIds.room(key).execute(poolIds, key);
  }, [refresh, poolIds.join(','), key]);

  return { pools, loading };
};

export const usePool = (poolId: string | undefined, realtime: boolean = true) => {
  const { pools, loading } = usePoolByIds(poolId ? [poolId] : [], poolId || '--non--');
  const { pool } = useRealtimePool(poolId);

  return useMemo(() => {
    let finalPool = pools[0];
    if (finalPool && realtime) {
      finalPool = {
        ...finalPool,
        ...(pool ? pool : {}),
      };
    }
    return {
      pool: finalPool,
      loading,
    };
  }, [pool, pools[0], loading, realtime]);
};

interface PoolItem
  extends Pick<
    TurbosPoolItem,
    | 'liquidity'
    | 'sqrt_price'
    | 'fee_growth_global_a'
    | 'fee_growth_global_b'
    | 'coin_a'
    | 'coin_b'
    | 'tick_current_index'
    | 'reward_infos'
    | 'reward_last_updated_time_ms'
    | 'protocol_fees_a'
    | 'max_liquidity_per_tick'
    | 'fee_protocol'
    | 'deploy_time_ms'
  > {}

export const useRealtimePool = (poolId: string | undefined) => {
  const { refresh } = useRefreshFlag();
  const [pool, setPool] = useState<PoolItem | undefined>();

  const getPool = async () => {
    if (poolId) {
      const poolObject = await turbosSdk.provider.getObject({
        id: poolId,
        options: { showContent: true, showType: true },
      });
      if (poolObject) {
        const fields = unstable_getObjectFields(poolObject) as PoolItem;
        if (fields) {
          return setPool({
            liquidity: fields.liquidity,
            sqrt_price: fields.sqrt_price,
            fee_growth_global_a: fields.fee_growth_global_a,
            fee_growth_global_b: fields.fee_growth_global_b,
            coin_a: fields.coin_a,
            coin_b: fields.coin_b,
            tick_current_index: turbosSdk.math.sqrtPriceX64ToTickIndex(new BN(fields.sqrt_price)),
            reward_infos: fields.reward_infos,
            reward_last_updated_time_ms: fields.reward_last_updated_time_ms,
            protocol_fees_a: fields.protocol_fees_a,
            max_liquidity_per_tick: fields.max_liquidity_per_tick,
            fee_protocol: fields.fee_protocol.toString(),
            deploy_time_ms: fields.deploy_time_ms,
          });
        }
      }
      setPool(undefined);
    } else {
      setPool(undefined);
    }
  };
  useEffect(() => {
    getPool();
  }, [poolId, refresh]);

  return {
    pool,
  };
};
