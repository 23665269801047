import { type DryRunTransactionBlockResponse } from '@mysten/sui/client';
import { type TransactionResult } from '@mysten/sui/transactions';

export enum OrderType {
  NO_RESTRICTION,
  IMMEDIATE_OR_CANCEL,
  FILL_OR_KILL,
  POST_ONLY,
}

// Self matching options
export enum SelfMatchingOptions {
  SELF_MATCHING_ALLOWED,
  CANCEL_TAKER,
  CANCEL_MAKER,
}

export interface CreateDepositThenPlaceLimitOrderParams {
  poolKey: string;
  baseCoinKey: string;
  quoteCoinKey: string;
  isBid: boolean;
  price: number;
  quantity: number;
  orderType?: OrderType;
  selfMatchingOption?: SelfMatchingOptions;
  feeIsDeep?: boolean;
  expireTimestamp?: number | bigint;
}

export interface CreateDepositThenPlaceMarketOrderParams
  extends Omit<CreateDepositThenPlaceLimitOrderParams, 'price'> {
  balance: number;
  coinObject?: TransactionResult;
}

export interface DepositThenPlaceLimitOrderParams extends CreateDepositThenPlaceLimitOrderParams {
  turbosBalanceManager: string;
  feeBalance: number;
}

export interface DepositThenPlaceMarketOrderParams extends CreateDepositThenPlaceMarketOrderParams {
  turbosBalanceManager: string;
}

export interface PlaceLimitOrderParams
  extends Omit<DepositThenPlaceLimitOrderParams, 'feeBalance'> {}

export interface DepositParams {
  turbosBalanceManager: string | TransactionResult;
  coinKey: string;
  amountToDeposit: number;
}

export interface WithdrawParams {
  turbosBalanceManager: string;
  coinKey: string;
  amountToWithdraw: number;
  recipient: string;
}

export interface WithdrawAllParams extends Omit<WithdrawParams, 'amountToWithdraw'> {}

export interface CancelOrderParams {
  turbosBalanceManager: string;
  poolKey: string;
  orderId: string;
}

export interface ShareParams extends Pick<DepositParams, 'turbosBalanceManager'> {}

export interface BalanceManagerEvents
  extends Array<
    Omit<DryRunTransactionBlockResponse['events'][number], 'parsedJson'> & {
      parsedJson: {
        list: string[];
        owner: string;
      };
    }
  > {}

export interface WithdrawSettledParams {
  turbosBalanceManager: string;
  poolKey: string;
}

export interface LimitAccount {
  epoch: string;
  open_orders: { constants: string[] };
  taker_volume: number;
  maker_volume: number;
  active_stake: number;
  inactive_stake: number;
  created_proposal: null | boolean;
  voted_proposal: null | { bytes: string };
  unclaimed_rebates: {
    base: number;
    quote: number;
    deep: number;
  };
  settled_balances: {
    base: number;
    quote: number;
    deep: number;
  };
  owed_balances: {
    base: number;
    quote: number;
    deep: number;
  };
}
