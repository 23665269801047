import { type FC, memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import WalletConnected from './wallet-connected';
import WalletUnconnect from './wallet-unconnect';
import { useModel } from 'foca';
import { walletConnectModel } from '@models/wallet-connect.model';
import { referralLinkModel } from '@models/referral-link.model';
import { useCurrentAccount, useCurrentWallet } from '@mysten/dapp-kit';
import { useAutoConnectWallet } from '@hooks/use-auto-connect-wallet';
import WalletDialog from './wallet-dialog';

const ConnectWallet: FC = () => {
  const wallet = useCurrentWallet();
  const account = useCurrentAccount();

  const [firstTime, setFirstTime] = useState(true);
  const autoConnect = useAutoConnectWallet();
  const showWallet = useModel(walletConnectModel, (state) => state.showWallet);
  const referralLinkCode = useModel(referralLinkModel, (state) => state.referralCode);

  useEffect(() => {
    if (!wallet.isConnected) return;
    if (autoConnect && firstTime) {
      setFirstTime(false);
    } else {
      toast('Connected to: ' + wallet.currentWallet.name);
    }
  }, [wallet.isConnected]);

  useEffect(() => {
    if (referralLinkCode && wallet.isConnected && account?.address) {
      referralLinkModel.invite(referralLinkCode, account.address);
    }
  }, [wallet.isConnected, account?.address]);

  if (!showWallet) return null;
  return (
    <>
      {wallet.isConnected ? <WalletConnected /> : <WalletUnconnect />}
      <WalletDialog />
    </>
  );
};

export default memo(ConnectWallet);
