import { type FC, memo, useEffect } from 'react';
import styles from './wallet-connected.module.scss';
import Dropdown from '@ui/dropdown';
import arrowIcon from '@assets/images/icons/arrow-down.svg';
import { useToggle } from 'react-use';

import {
  useCurrentAccount,
  useCurrentWallet,
  useDisconnectWallet,
  useResolveSuiNSName,
} from '@mysten/dapp-kit';
import WalletList from './wallet-list';
import { deepbook } from '@libs/deepbook';
import { formatAddress } from '@mysten/sui/utils';

const WalletConnected: FC = () => {
  const currentAccount = useCurrentAccount();
  const wallet = useCurrentWallet();
  const suiNames = useResolveSuiNSName(currentAccount?.address);
  const [showMenu, toggleShowMenu] = useToggle(false);
  const { mutate: disconnect } = useDisconnectWallet();

  useEffect(() => {
    // Disconnect from wallet extension
    !currentAccount && disconnect();
  }, [!!currentAccount]);

  const updateAddress = async () => {
    if (currentAccount) {
      deepbook.updateAddress(currentAccount.address);
    }
  };

  useEffect(() => {
    updateAddress();
  }, [currentAccount]);

  return (
    <Dropdown
      onVisibleChange={toggleShowMenu}
      placement="bottomRight"
      overlay={<WalletList />}
      onOverlayClick={toggleShowMenu}
    >
      <div className={`${styles.button} ${showMenu ? styles.active : ''}`} key="connected">
        <img src={wallet.currentWallet?.icon} className={styles.wallet_icon} />
        <span>
          {suiNames.data
            ? suiNames.data
            : currentAccount?.address
            ? formatAddress(currentAccount.address)
            : ''}
        </span>
        <img src={arrowIcon} className={styles.arrow_icon} />
      </div>
    </Dropdown>
  );
};

export default memo(WalletConnected);
