import type { TurbosPoolItem } from '@models/pool.model';
import { useCoin, useStableCoinIds } from './use-coins';
import { turbosSdk } from '@libs/turbos-sdk';
import { Decimal } from 'turbos-clmm-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useSqrtPriceToPrice } from './use-sqrt-price-to-price';
import { usePrice } from './use-price';

export const usePoolPriceAndLiquidity = (
  pool?: Pick<TurbosPoolItem, 'coin_type_a' | 'coin_type_b' | 'sqrt_price' | 'coin_a' | 'coin_b'>,
) => {
  const coinA = useCoin(pool?.coin_type_a);
  const coinB = useCoin(pool?.coin_type_b);
  const coinBPrice = usePrice(coinB?.type || '');
  const [prices, setPrices] = useState<{ currentPrice: string; priceA: string; priceB: string }>();
  const [liquidities, setLiquidities] = useState<{
    liquidityA: string;
    liquidityB: string;
    liquidity: string;
  }>();
  const currentPrice = useSqrtPriceToPrice(pool?.sqrt_price, pool?.coin_type_a, pool?.coin_type_b);
  const stableCoins = useStableCoinIds();

  useEffect(() => {
    if (!coinA || !coinB || !currentPrice) return;

    if (coinBPrice?.price) {
      const _coinBPrice = coinBPrice.price;
      setPrices({
        priceA: new Decimal(currentPrice).mul(_coinBPrice).toString(),
        priceB: _coinBPrice.toString(),
        currentPrice: currentPrice,
      });
    } else if (stableCoins.includes(coinA.type)) {
      setPrices({
        priceA: '1',
        priceB: new Decimal(1).dividedBy(currentPrice).toString(),
        currentPrice: currentPrice,
      });
    } else if (stableCoins.includes(coinB.type)) {
      setPrices({
        priceA: currentPrice,
        priceB: '1',
        currentPrice: currentPrice,
      });
    } else {
      setPrices(undefined);
    }
  }, [currentPrice, coinA, coinB, coinBPrice?.price, stableCoins]);

  // pool may be temp object, DO NOT use it as deps.
  useEffect(() => {
    if (!prices || !pool) return;
    turbosSdk.pool
      .getFixedLiquidity({
        coinTypeA: pool.coin_type_a,
        coinTypeB: pool.coin_type_b,
        amountA: pool.coin_a,
        amountB: pool.coin_b,
        priceA: prices.priceA,
        priceB: prices.priceB,
      })
      .then(setLiquidities);
  }, [prices, pool?.coin_a, pool?.coin_b, pool?.coin_type_a, pool?.coin_type_b]);

  return useMemo(
    () => (prices && liquidities ? { ...prices, ...liquidities } : undefined),
    [prices, liquidities],
  );
};
