import { useCoin } from '@hooks/use-coins';
import { globalSettingModel } from '@models/global-setting.model';
import { swapCoinModel } from '@models/swap-coin.model';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { Decimal } from 'turbos-clmm-sdk';
import { useComputed, useModel } from 'foca';
import { type FC, memo } from 'react';

interface OwnProps {
  amountIn: string;
  amountOut: string;
  amountSpecifiedIsInput: boolean;
}

const ExpectedAmount: FC<OwnProps> = (props) => {
  const { coinTypeA, coinTypeB } = useModel(swapCoinModel);
  const coinA = useCoin(coinTypeA)!;
  const coinB = useCoin(coinTypeB)!;
  const slippage = useComputed(globalSettingModel.realSlippage);
  const { amountIn, amountOut, amountSpecifiedIsInput } = props;
  const amount = amountSpecifiedIsInput
    ? new Decimal(100).minus(slippage).dividedBy(100).mul(amountOut)
    : new Decimal(100).plus(slippage).dividedBy(100).mul(amountIn);
  const decimals = amountSpecifiedIsInput ? coinB.decimals : coinA.decimals;
  const symbol = amountSpecifiedIsInput ? coinB.symbol : coinA.symbol;

  return (
    <>
      <span>{amountSpecifiedIsInput ? 'Minimum Received' : 'Maximum Sold'}</span>
      <span>
        {bigNumberToReadable(amount, decimals)}&nbsp;{symbol}
      </span>
    </>
  );
};

export default memo(ExpectedAmount);
