export const formatAddress = ({
  domain,
  label,
  address,
}: {
  domain?: string;
  label?: string;
  address?: string;
}) => {
  if (domain) {
    const length = 15;
    domain = domain.replace(/\.sui$/, '');
    return domain.slice(0, length) + (domain.length > length ? '...' : '');
  }

  label = stripEthosWallet(label);
  if (label) {
    return label.replace(/^(.{6}).*?(.{0,4})$/, `$1${label.length > 10 ? '...' : ''}$2`);
  }

  if (address) {
    return address.replace(/^(.{6}).*?(.{0,4})$/, `$1${address.length > 10 ? '...' : ''}$2`);
  }

  return '';
};

export const stripEthosWallet = (label: string | undefined) => {
  if (!label || label === 'Ethos Wallet') return '';
  return label;
};
