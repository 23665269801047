import { useMemo } from 'react';

const coins = ['0xaf8cd5edc19c4512f4259f0bee101a40d41ebed738ade5874359610ef8eeced5::coin::COIN'];

export const useCoinTypeMin = (type: string) => {
  return useMemo(() => {
    return {
      min: coins.includes(type) ? 0.0001 : 0.1,
      minDecimal: coins.includes(type) ? 4 : 1,
    };
  }, [type]);
};
