import { defineModel } from 'foca';
import http from 'src/services/http';

export interface TurbosUserRankingItem {
  address: string;
  trade_volume: string;
}

const initialState: TurbosUserRankingItem[] = [];

export const userRankingModel = defineModel('userRanking', {
  initialState,
  methods: {
    async getUserRanking() {
      const result = await http.get<TurbosUserRankingItem[]>(`/dex/user-ranking`, {
        cache: {
          maxAge: 60_000,
        },
      });
      this.setState(result);
      return result;
    },
  },
});
