import Button from '@ui/button';
import { type FC, memo } from 'react';
import styles from './swap-submit.module.scss';
import { Decimal } from 'turbos-clmm-sdk';
import { useBalance } from '@hooks/use-balances';
import { useCoin } from '@hooks/use-coins';
import { turbosSdk } from 'src/services/turbos-sdk';
import { useComputed, useModel } from 'foca';
import { nativeCoinModel } from '@models/native-coin.model';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { useAddress } from '@hooks/use-address';
import { walletConnectModel } from '@models/wallet-connect.model';
import { useTransaction } from '@hooks/use-transaction';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { TransactionStatus } from '@constants/transaction-status';
import { globalSettingModel } from '@models/global-setting.model';
import { transactionModel } from '@models/transaction.model';
import { Transaction } from '@mysten/sui/transactions';
import { deepbook } from '@libs/deepbook';
import { useInMsafeWallet } from '@hooks/use-in-msafe-wallet';
import Lottie from 'lottie-react';
import TradedJSON from '@assets/lottie/Traded.json';
import successImg from '@assets/images/deepbook/success.svg';
import { Link } from 'react-router-dom';
// import { useImpact } from '@hooks/use-impact';

interface OwnProps {
  fetchingBestRoute: boolean;
  bestRoutes: BestSwapRouterType[] | undefined;
  insufficientLiquidity: boolean;
  amountIn: string | undefined;
  amountOut: string | undefined;
  amountSpecifiedIsInput: boolean;
  isDeepbook: boolean;
  transactionBlock: Transaction | undefined;
  onTransacted(): void;
}

const SwapSubmit: FC<OwnProps> = (props) => {
  const {
    fetchingBestRoute,
    bestRoutes,
    insufficientLiquidity,
    amountIn,
    amountOut,
    amountSpecifiedIsInput,
    isDeepbook,
    transactionBlock,
    onTransacted,
  } = props;

  const inMsafeWallet = useInMsafeWallet();

  const { coinTypeA, coinTypeB } = useModel(nativeCoinModel);
  const { status: transactionStatus } = useModel(transactionModel);
  const transact = useTransaction('swap');
  const slippage = useComputed(globalSettingModel.realSlippage);
  const address = useAddress();
  const balanceA = useBalance(coinTypeA);
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);
  // const impact = useImpact(bestRoutes);

  let msg = 'Trade';
  let disabled = false;
  let loading = false;
  let clickEvent: (() => void) | undefined;

  // const ratio = coinA?.is_stable && coinB?.is_stable ? 1 : 10;
  if (fetchingBestRoute) {
    msg = 'Fetching best price';
    loading = true;
    disabled = true;
  } else if (!address) {
    msg = 'Connect Wallet';
    disabled = false;
    clickEvent = walletConnectModel.toggleDialog;
  } else if (!coinTypeA || !coinTypeB) {
    msg = 'Select token';
    disabled = true;
  } else if (bestRoutes && !bestRoutes.length && !isDeepbook) {
    msg = `Route doesn't exist!`;
    disabled = true;
  } else if (insufficientLiquidity) {
    msg = 'Insufficient Liquidity';
    disabled = true;
  } else if (coinA && amountIn && (!balanceA || new Decimal(amountIn).greaterThan(balanceA))) {
    msg = `Not enough ${coinA.symbol}`;
    disabled = true;
  } else if (!coinA || !coinB || !balanceA || !amountIn || !amountOut || !bestRoutes) {
    disabled = true;
    if (isDeepbook) {
      const poolA = deepbook.findPool(coinTypeA, coinTypeB);
      const poolB = deepbook.findPool(coinTypeB, coinTypeA);
      if (!poolA && !poolB) {
        msg = 'Not Pool';
      }
    }
  }
  // else if (!isDeepbook && (!impact || impact.gt(ratio))) {
  //   disabled = true;
  //   msg = 'price out of the market';
  // }
  else if (isDeepbook) {
    if (transactionBlock) {
      loading = transactionStatus === TransactionStatus.confirm;
      clickEvent = async () => {
        const succeed = await transact(
          <>
            Traded{' '}
            <b>
              {bigNumberToReadable(amountIn, coinA.decimals)} {coinA.symbol}
            </b>{' '}
            for{' '}
            <b>
              {bigNumberToReadable(amountOut, coinB.decimals)} {coinB.symbol}
            </b>
          </>,
          async () => {
            return transactionBlock;
          },
          {
            successTip: (
              <>
                <img src={successImg} />
              </>
            ),
            description: (
              <>
                Traded{' '}
                <b>
                  {bigNumberToReadable(amountIn, coinA.decimals)} {coinA.symbol}
                </b>{' '}
                for{' '}
                <b>
                  {bigNumberToReadable(amountOut, coinB.decimals)} {coinB.symbol}
                </b>
                <div className={styles.deepbook_yields}>
                  Provide liquidity to earn boosted yields <br></br>
                  <Link
                    onClick={transactionModel.toggleVisible}
                    to="/pools/0x770010854059edf1dd3d49a97f3054c39b870ec708fe2f408e30a8ef4724caef/add-liquidity"
                  >
                    USDT-USDC
                  </Link>
                </div>
              </>
            ),
          },
        );

        succeed && onTransacted();
      };
    }
  } else {
    loading = transactionStatus === TransactionStatus.confirm;
    clickEvent = async () => {
      const succeed = await transact(
        <>
          Traded{' '}
          <b>
            {bigNumberToReadable(amountIn, coinA.decimals)} {coinA.symbol}
          </b>{' '}
          for{' '}
          <b>
            {bigNumberToReadable(amountOut, coinB.decimals)} {coinB.symbol}
          </b>
        </>,
        async () => {
          return turbosSdk.trade.swap({
            routes: bestRoutes.map(({ swapResult, nextTickIndex }) => ({
              pool: swapResult!.pool,
              a2b: swapResult!.a_to_b,
              nextTickIndex,
            })),
            coinTypeA: coinA.type,
            coinTypeB: coinB.type,
            address,
            amountA: amountIn,
            amountB: amountOut,
            amountSpecifiedIsInput,
            slippage,
            deadline: inMsafeWallet ? 60 * 60 * 1000 : undefined,
          });
        },
        {
          successTip: (
            <>
              <Lottie animationData={TradedJSON} autoplay loop />
            </>
          ),
        },
      );

      succeed && onTransacted();
    };
  }

  return (
    <Button
      fullWidth
      type="highlight"
      size="large"
      disabled={disabled}
      className={styles.button}
      loading={loading}
      onClick={clickEvent}
    >
      {msg}
    </Button>
  );
};

export default memo(SwapSubmit);
