import { useCoin } from '@hooks/use-coins';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { type FC, memo } from 'react';
import { usePoolPriceAndLiquidity } from '@hooks/use-pool-price-and-liquidity';
import { Decimal } from 'turbos-clmm-sdk';
import { formatDigitUnit } from '@utils/format-digit-unit';

interface OwnProps {
  bestRoutes: BestSwapRouterType[] | undefined;
  amountIn?: string;
  className?: string;
}

const CoinAPrice: FC<OwnProps> = ({ bestRoutes, amountIn, className }) => {
  const pool = bestRoutes?.[0]?.pool;
  const coinA = useCoin(pool?.coin_type_a);
  const coinB = useCoin(pool?.coin_type_b);
  const PPAL = usePoolPriceAndLiquidity(pool);

  if (!PPAL || !amountIn || !pool) return null;

  const price = pool.a_to_b ? PPAL.priceA : PPAL.priceB;
  const decimals = pool.a_to_b ? coinA!.decimals : coinB!.decimals;

  return (
    <div className={className}>
      ${formatDigitUnit(new Decimal(price).mul(amountIn).div(10 ** decimals))}
    </div>
  );
};

export default memo(CoinAPrice);
