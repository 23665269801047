import { type FC, memo } from 'react';
import styles from './index.module.scss';
import people1Img from '@assets/images/say/people-1.png';
import people2Img from '@assets/images/say/people-2.png';
import people3Img from '@assets/images/say/people-3.png';
import people4Img from '@assets/images/say/people-4.jpg';
import people5Img from '@assets/images/say/people-5.jpg';
import marksImg from '@assets/images/say/marks.svg';
import tokenImg from '@assets/images/say/token.svg';
import token1Img from '@assets/images/say/token1.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const Say: FC = () => {
  return (
    <div className={`${styles.wrapper} `}>
      <Swiper
        modules={[Autoplay, Pagination]}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide>
          <div className={styles.content}>
            <img src={marksImg} className={styles.mark} />
            <div className={styles.say}>
              "We couldn’t ask for more from the Turbos team. They operate with the utmost care and
              integrity while providing stellar products and advice. Their team goes above and
              beyond to support early stage and mature projects in the Sui ecosystem. We recommend
              any and all Sui projects to work with Turbos for the best DeFi operations Sui has to
              offer."{' '}
            </div>
            <div className={styles.people}>
              <img src={people1Img} className={styles.icon} />
              <div className={styles.info}>
                <p className={styles.name}>Shoto</p>
                <div className={styles.work}>
                  <span className={styles.profession}>Cofounder & CEO DeSuiLabs</span>
                  <span className={styles.border}></span>
                  <span className={styles.token_icon}>
                    <img src={tokenImg} />
                  </span>
                  <span className={styles.url}>desuilabs.io</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.content}>
            <img src={marksImg} className={styles.mark} />
            <div className={styles.say}>
              "If it wasn't for Turbos our project would have hit zero a long time ago"
            </div>
            <div className={styles.people}>
              <img src={people2Img} className={styles.icon} />
              <div className={styles.info}>
                <div className={styles.work}>
                  <span className={styles.profession}>Contributors, FudThePug</span>
                  <span className={styles.border}></span>
                  <span className={styles.token_icon}>
                    <img src={people2Img} />
                  </span>
                  <span className={styles.symbol}>$FUD</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.content}>
            <img src={marksImg} className={styles.mark} />
            <div className={styles.say}>
              "Working with Turbos was a pleasure, they were extremely helpful every step of the
              way, from setting up pools to managing liquidity."
            </div>
            <div className={styles.people}>
              <img src={people3Img} className={styles.icon} />
              <div className={styles.info}>
                <p className={styles.name}>juzy</p>
                <div className={styles.work}>
                  <span className={styles.profession}>Founder of SPAM</span>
                  <span className={styles.border}></span>
                  <span className={styles.token_icon}>
                    <img src={token1Img} />
                  </span>
                  <span className={styles.symbol}>$SPAM</span>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.content}>
            <img src={marksImg} className={styles.mark} />
            <div className={styles.say}>
              "The support and partnership from Turbos since day one have been crucial for BLUB's
              success. Their quick and generous assistance has not only helped us optimize our
              liquidity strategy and enhance our marketing campaigns but has also fostered a
              collaborative environment that strengthens our overall market presence and drives
              sustained growth. Turbos is a key ally in our journey."
            </div>
            <div className={styles.people}>
              <img src={people4Img} className={styles.icon} />
              <div className={styles.info}>
                <p className={styles.name}>GD, contributor of BLUB</p>
                {/* <div className={styles.work}>
                  <span className={styles.profession}>Founder of SPAM</span>
                  <span className={styles.border}></span>
                  <span className={styles.token_icon}>
                    <img src={token1Img} />
                  </span>
                  <span className={styles.symbol}>$SPAM</span>
                </div> */}
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className={styles.content}>
            <img src={marksImg} className={styles.mark} />
            <div className={styles.say}>
              "Easy to understand UI and various effective features make Turbos the perfect choice
              for both the novice and seasoned DEX users. Best platform on SUI to launch project,
              build community and accelerate growth."
            </div>
            <div className={styles.people}>
              <img src={people5Img} className={styles.icon} />
              <div className={styles.info}>
                <p className={styles.name}>Dave - BLUB, Community Lead</p>
                {/* <div className={styles.work}>
                  <span className={styles.profession}>Founder of SPAM</span>
                  <span className={styles.border}></span>
                  <span className={styles.token_icon}>
                    <img src={token1Img} />
                  </span>
                  <span className={styles.symbol}>$SPAM</span>
                </div> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default memo(Say);
