import TurbosLink from '@pages/deepbook-v2-swap/turbos-link';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './swap-native.module.scss';

const SwapNative: FC = () => {
  return (
    <div className={styles.step}>
      <p>
        <Link to="/native-usdc">Obtain Native USDC</Link> and earn{' '}
        <span>juicy $SUl and $TURBOS reward</span> in native USDC pools!
      </p>

      <ul className={styles.items}>
        <li>
          <TurbosLink poolId="0x770010854059edf1dd3d49a97f3054c39b870ec708fe2f408e30a8ef4724caef" />
        </li>
        <li>
          <TurbosLink poolId="0x77f786e7bbd5f93f7dc09edbcffd9ea073945564767b65cf605f388328449d50" />
        </li>
      </ul>
    </div>
  );
};

export default SwapNative;
