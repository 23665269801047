import { type FC } from 'react';
import warnIcon from '@assets/images/icons/warn.svg';
import verifiedIcon from '@assets/images/icons/verified.svg';
import Tooltip, { type TooltipProps } from '@ui/tooltip';
import styles from './warn.module.scss';

interface OwnProps {
  placement?: TooltipProps['placement'];
  type?: 'warn' | 'verified';
  title?: string;
}

const Warn: FC<OwnProps> = ({ placement = 'top', type = 'warn' }) => {
  let icon = warnIcon;
  if (type === 'verified') {
    icon = verifiedIcon;
  }

  return (
    <Tooltip
      overlay={
        <div className={styles.tip}>
          {type === 'verified'
            ? 'Verified Token'
            : 'This token appears to be a fake version of a token from a recognized project. Please verify its authenticity before proceeding.'}
        </div>
      }
      placement={placement}
    >
      <img src={icon} />
    </Tooltip>
  );
};

export default Warn;
