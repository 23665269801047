import SelectToken from '@components/select-token';
import { useCoin } from '@hooks/use-coins';
import {
  type FC,
  memo,
  useCallback,
  type ChangeEventHandler,
  useMemo,
  useState,
  useEffect,
} from 'react';
import styles from './swap-section.module.scss';
import { useBalance } from '@hooks/use-balances';
import arrowIcon from '@assets/images/icons/arrow-down.svg';
import Input from '@ui/input';
import { Decimal } from 'turbos-clmm-sdk';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { filterPositiveNumber } from '@utils/filter-positive-number';
import { turbosSdk } from '@libs/turbos-sdk';
import CoinLogo from '@ui/coin-logo';
import Warn from '@components/pool-item/warn';
import { HotTokenIcon } from '@components/symbol-pair/hot-token-icon';

interface OwnProps {
  label: string;
  showMaxAmount: boolean;
  coinType: string | undefined;
  disableCoins?: string | string[];
  onSelectCoin: (coinType: string) => void;
  amount: string | undefined;
  inputByUser: boolean;
  onChangeValue: (amount: string | undefined) => void;
  inputDisabled?: boolean;
  onChangeAmount: (amount: string | undefined) => void;
}

/**
 * Scenarios:
 * - user input a
 * - user input b
 * - user click balance when input a
 * - user click balance when input b
 * - user input a and focus on b
 * - user input b and focus on a
 * - swap
 */
const SwapSection: FC<OwnProps> = (props) => {
  const {
    label,
    coinType,
    disableCoins,
    onSelectCoin,
    showMaxAmount,
    amount,
    onChangeValue,
    inputByUser,
    inputDisabled,
    onChangeAmount,
  } = props;
  const coin = useCoin(coinType);
  const coinBalance = useBalance(coinType);
  const [inputtingValue, setInputtingValue] = useState('');
  const [focus, setFocus] = useState(false);

  const readableValue = useMemo(() => {
    return amount ? bigNumberToReadable(amount, coin?.decimals, false, undefined, false) : '';
  }, [amount]);

  useEffect(() => {
    if (focus) {
      // user is inputting
      return;
    }
    if (!inputByUser) {
      // not exact in
      setInputtingValue(readableValue);
    } else if (amount === undefined || !coin) {
      setInputtingValue('');
    } else {
      setInputtingValue(turbosSdk.math.scaleDown(amount, coin.decimals));
    }
  }, [inputByUser]);

  useEffect(() => {
    !inputByUser && setInputtingValue(readableValue);
  }, [readableValue]);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const { num, str } = filterPositiveNumber(e.target.value);
      setInputtingValue(str);
      if (num > 0) {
        onChangeValue(
          new Decimal(num)
            .mul(10 ** coin!.decimals)
            .floor()
            .toString(),
        );
      } else {
        onChangeValue(undefined);
      }
    },
    [onChangeValue, coin?.decimals],
  );

  useEffect(() => {
    if (inputByUser && coin?.decimals !== undefined && inputtingValue !== '' && readableValue) {
      onChangeAmount(
        new Decimal(inputtingValue)
          .mul(10 ** coin.decimals)
          .floor()
          .toString(),
      );
    }
  }, [coin?.decimals, inputByUser, onChangeAmount, inputtingValue, readableValue]);

  const handleSetMax = useCallback(() => {
    if (!coinBalance || !coin) return;
    setInputtingValue(turbosSdk.math.scaleDown(coinBalance, coin.decimals));
    onChangeValue(coinBalance);
  }, [coinBalance, coin?.decimals, onChangeValue]);

  const handleSetHalf = useCallback(() => {
    if (!coinBalance || !coin) return;
    const half = new Decimal(coinBalance).div(2).floor().toString();
    setInputtingValue(turbosSdk.math.scaleDown(half, coin.decimals));
    onChangeValue(half);
  }, [coinBalance, coin?.decimals, onChangeValue]);

  return (
    <>
      <div className={styles.row}>
        <p className={styles.label}>{label}</p>
        {showMaxAmount && !!coin && (
          <p className={styles.max_number}>
            Max:&nbsp;
            <span onClick={handleSetMax}>{bigNumberToReadable(coinBalance, coin.decimals)}</span>
          </p>
        )}
      </div>
      <div className={styles.row}>
        <SelectToken disableCoins={disableCoins} onSelected={onSelectCoin} coinType={coinType}>
          <div className={styles.select}>
            {coin ? (
              <>
                <CoinLogo src={coin.logo_url} />
                <div className={styles.symbol}>
                  <p>{coin.symbol}</p>
                  <p className={styles.cross_chain}>{coin.cross_chain}</p>
                </div>
                {coin.flag === 1 && (
                  <div className={styles.icon}>
                    <Warn type="verified" />
                  </div>
                )}
                {coin.flag === 2 && (
                  <div className={styles.icon}>
                    <Warn />
                  </div>
                )}
                <HotTokenIcon hot={coin.is_hot} className={styles.icon} />
              </>
            ) : (
              <div className={styles.symbol}>Select token</div>
            )}
            <img src={arrowIcon} />
          </div>
        </SelectToken>
        <Input
          prefix={
            showMaxAmount && (
              <div className={styles.half} onClick={handleSetHalf}>
                HALF
              </div>
            )
          }
          placeholder="0"
          type="number"
          align="right"
          value={inputtingValue}
          className={`${styles.input} ${showMaxAmount ? styles.has_prefix : ''}`}
          disabled={!coin || inputDisabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </>
  );
};

export default memo(SwapSection);
