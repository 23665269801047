import { Decimal } from 'turbos-clmm-sdk';

const POSTFIXES = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'];

export const formatDigitUnit = (
  value: Decimal.Value | string | number | undefined,
  maximumFractionDigits = 2,
): string => {
  if (!value) {
    return '0.00';
  }

  const bigVal = new Decimal(value);

  if (bigVal.toFixed() === 'NaN' || !bigVal.toFixed()) {
    return bigVal.toFixed();
  }

  const len = bigVal.toFixed(0).length;
  const significantDigitsGroup = Math.min(
    Math.floor(len ? (len - 1) / 3 : 0),
    POSTFIXES.length - 1,
  );
  const postfix = POSTFIXES[significantDigitsGroup];
  const formattedValue = bigVal.dividedBy(10 ** (3 * significantDigitsGroup)).toNumber();
  return Number(formattedValue.toFixed(maximumFractionDigits)).toString() + postfix;
};
