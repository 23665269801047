import { store, type Middleware } from 'foca';
// import { swapCoinModel } from './swap-coin.model';
import { globalSettingModel } from './global-setting.model';
import { favoritePoolModel } from './favorite-pool.model';
import { privacyModel } from './privacy.model';
// import { createLogger } from 'redux-logger';
import { priceModel } from './price.model';
import { coingeckoModel } from './coingecko.model';
import { walletConnectModel } from './wallet-connect.model';
// import { referralLinkModel } from './referral-link.model';

const middleware: Middleware[] = [];

if (process.env.NODE_ENV !== 'production') {
  // middleware.push(
  //   createLogger({
  //     collapsed: true,
  //     diff: true,
  //     duration: true,
  //     logErrors: true,
  //   }),
  // );
}

store.init({
  compose: 'redux-devtools',
  middleware,
  persist: [
    {
      key: 'turbos-' + (import.meta.env.MODE === 'mainnet' ? 'production' : import.meta.env.MODE),
      version: 1,
      engine: localStorage,
      models: [
        // swapCoinModel,
        globalSettingModel,
        favoritePoolModel,
        // referralLinkModel,
        priceModel,
        coingeckoModel,
        walletConnectModel,
      ],
    },
    {
      key: 'turbos-privacy',
      version: 1,
      engine: localStorage,
      models: [privacyModel],
    },
  ],
});

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('Store updated');
  });
}
