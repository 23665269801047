import http from '@libs/http';
import { defineModel } from 'foca';

const initialState: Record<
  string,
  { price: number; percentage: number | null; time: number; source: string }
> = {};

export const priceModel = defineModel('price', {
  initialState,
  methods: {
    async fetchPrice(coinType: string) {
      const cache = this.state[coinType];
      if (cache && cache.time + 60_000 >= Date.now()) return;

      const coinPrice = await http.get<{
        type: string;
        price: number;
        change24h: number | null;
        source: string;
      }>('/price', {
        params: {
          coinType,
        },
      });

      if (!coinPrice) {
        return;
      }

      this.setState({
        [coinType]: {
          price: coinPrice.price,
          percentage: coinPrice.change24h ? Number(coinPrice.change24h) : coinPrice.change24h,
          time: Date.now(),
          source: coinPrice.source,
        },
      });
    },
  },
});
