import { type FC, memo } from 'react';
import styles from './index.module.scss';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import SelectSlippage from '@components/select-slippage';
import { useToggle } from 'react-use';
import CoinPath from './coin-path';
import ExpectedAmount from './expected-amount';
import ExchangeRate from './exchange-rate';
import PriceStatus from './price-status';
import SlippageTolerance from './slippage-tolerance';
import PriceImpact from './price-impact';
import TradeFees from './trade-fees';
import { useSwapPriceStatus } from '@hooks/use-swap-price-status';
import { useModel } from 'foca';
import { swapCoinModel } from '@models/swap-coin.model';
import type { RouterCompleteTradeRoute } from 'non-restrictive-aftermath-ts-sdk';

interface OwnProps {
  amountIn: string | undefined;
  amountOut: string | undefined;
  bestRoutes: BestSwapRouterType[] | undefined;
  isDeepbook: boolean;
  isAftermath: boolean;
  aftermathRoute: RouterCompleteTradeRoute | undefined;
  amountSpecifiedIsInput: boolean;
}

const RouteInfo: FC<OwnProps> = (props) => {
  const { coinTypeA, coinTypeB } = useModel(swapCoinModel);
  const {
    amountIn,
    amountOut,
    bestRoutes,
    amountSpecifiedIsInput,
    isDeepbook,
    isAftermath,
    aftermathRoute,
  } = props;
  const [showInfo, toggleShowInfo] = useToggle(false);

  const priceStatus = useSwapPriceStatus(
    coinTypeA,
    coinTypeB,
    amountIn,
    amountOut,
    isAftermath,
    aftermathRoute,
  );

  if (!amountIn || !amountOut || (!isAftermath && !bestRoutes?.length)) return null;

  return (
    <div className={styles.wrapper}>
      <ExchangeRate
        amountIn={amountIn}
        amountOut={amountOut}
        bestRoutes={bestRoutes}
        isDeepbook={isDeepbook}
        aftermathRoute={aftermathRoute}
      />
      {!isDeepbook && (
        <>
          <div className={styles.row}>
            <PriceStatus status={priceStatus} show={showInfo} onClick={toggleShowInfo} />
            <SelectSlippage />
          </div>
        </>
      )}

      {showInfo && (
        <>
          <div className={styles.swap_info}>
            <CoinPath bestRoutes={bestRoutes} isDeepbook={isDeepbook} isAftermath={isAftermath} />
          </div>

          {!isDeepbook && (
            <>
              <div className={styles.swap_info}>
                <ExpectedAmount
                  amountIn={amountIn}
                  amountOut={amountOut}
                  amountSpecifiedIsInput={amountSpecifiedIsInput}
                />
              </div>
              <div className={styles.swap_info}>
                <SlippageTolerance />
              </div>
              {bestRoutes && (
                <div className={styles.swap_info}>
                  <PriceImpact bestRoutes={bestRoutes} />
                </div>
              )}
            </>
          )}

          <div className={styles.swap_info}>
            {!bestRoutes && !aftermathRoute ? null : (
              <TradeFees
                bestRoutes={bestRoutes}
                isDeepbook={isDeepbook}
                aftermathRoute={aftermathRoute}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(RouteInfo);
