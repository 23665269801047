import { useCoin } from '@hooks/use-coins';
import { usePool } from '@hooks/use-pools';
import Loading from '@ui/loading';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './turbos-link.module.scss';

interface OwnProps {
  poolId: string;
}
const TurbosLink: FC<OwnProps> = ({ poolId }) => {
  const { pool, loading } = usePool(poolId);
  const coinA = useCoin(pool?.coin_type_a);
  const coinB = useCoin(pool?.coin_type_b);

  if (loading && !pool)
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    );

  if (!pool) return null;

  return (
    <Link to={`/pools/${poolId}/add-liquidity`}>
      <div className={styles.pair}>
        <img src={coinA?.logo_url} />
        <img src={coinB?.logo_url} />
        <span>
          {pool.coin_symbol_a} / {pool.coin_symbol_b}
        </span>
      </div>
      <div className={styles.apr}>
        APR
        <span>{pool.apr.toFixed(2)}%</span>
      </div>
    </Link>
  );
};

export default TurbosLink;
