import { useCoin } from '@hooks/use-coins';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { turbosSdk } from '@libs/turbos-sdk';
import { Decimal } from 'turbos-clmm-sdk';
import { type FC, memo } from 'react';
import { usePoolPriceAndLiquidity } from '@hooks/use-pool-price-and-liquidity';
import { formatDigitUnit } from '@utils/format-digit-unit';
import { useAftermathPrice } from '@hooks/use-aftermath-price';

interface OwnProps {
  bestRoutes: BestSwapRouterType[] | undefined;
  amountIn?: string;
  amountOut?: string;
  className?: string;
  isAftermath?: boolean;
  coinAType?: string;
  coinBType?: string;
}

const CoinBPrice: FC<OwnProps> = ({
  bestRoutes,
  amountIn,
  amountOut,
  className,
  isAftermath,
  coinAType,
  coinBType,
}) => {
  const firstPool = bestRoutes?.[0]?.pool;
  const lastPool = bestRoutes?.[bestRoutes.length - 1]?.pool;
  const firstCoinA = useCoin(firstPool?.coin_type_a);
  const firstCoinB = useCoin(firstPool?.coin_type_b);
  const lastCoinA = useCoin(lastPool?.coin_type_a);
  const lastCoinB = useCoin(lastPool?.coin_type_b);
  const firstPPAL = usePoolPriceAndLiquidity(firstPool);
  const lastPPAL = usePoolPriceAndLiquidity(lastPool);
  const aftermathACoin = useCoin(isAftermath ? coinAType : undefined);
  const aftermathBCoin = useCoin(isAftermath ? coinBType : undefined);
  const aftermathAPrice = useAftermathPrice(isAftermath ? coinAType : undefined);
  const aftermathBPrice = useAftermathPrice(isAftermath ? coinBType : undefined);

  let totalPriceB: string | undefined, ratio: string | undefined;

  if (
    aftermathACoin &&
    aftermathBCoin &&
    aftermathAPrice &&
    aftermathBPrice &&
    amountOut &&
    amountIn
  ) {
    totalPriceB = turbosSdk.math.scaleDown(
      new Decimal(aftermathBPrice).mul(amountOut),
      aftermathBCoin.decimals,
    );

    const totalPriceA = turbosSdk.math.scaleDown(
      new Decimal(aftermathAPrice).mul(amountIn),
      aftermathACoin.decimals,
    );

    const r = new Decimal(totalPriceA).minus(totalPriceB).div(totalPriceA);
    ratio = r.gte(0.0001) ? r.mul(100).neg().toFixed(2) + '%' : '< 0.01%';
  }

  if (lastPPAL && amountOut && lastPool) {
    const priceB = new Decimal(lastPool.a_to_b ? lastPPAL.priceB : lastPPAL.priceA);
    const decimals = lastPool.a_to_b ? lastCoinB!.decimals : lastCoinA!.decimals;
    totalPriceB = turbosSdk.math.scaleDown(priceB.mul(amountOut), decimals);
  }

  if (!totalPriceB) return null;

  if (firstPPAL && amountIn && firstPool) {
    const priceA = new Decimal(firstPool.a_to_b ? firstPPAL.priceA : firstPPAL.priceB);
    const decimals = firstPool.a_to_b ? firstCoinA!.decimals : firstCoinB!.decimals;
    const totalPriceA = turbosSdk.math.scaleDown(priceA.mul(amountIn), decimals);
    const r = new Decimal(totalPriceA).minus(totalPriceB).div(totalPriceA);
    ratio = r.gte(0.0001) ? r.mul(100).neg().toFixed(2) + '%' : '< 0.01%';
  }

  return (
    <div className={className}>
      ${formatDigitUnit(totalPriceB)}
      {!!ratio && <span>&nbsp;({ratio})</span>}
    </div>
  );
};

export default memo(CoinBPrice);
