import { defineModel } from 'foca';

const initialState: string[] = [];

export const favoritePoolModel = defineModel('favorite-pools', {
  initialState,
  reducers: {
    append(state, poolId: string) {
      state.push(poolId);
    },
    remove(state, poolId: string) {
      return state.filter((id) => id !== poolId);
    },
  },
});
