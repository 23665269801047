import Tooltip from '@ui/tooltip';
import { type FC, memo, useState, type FocusEventHandler, type ChangeEventHandler } from 'react';
import settingIcon from '@assets/images/icons/setting.svg';
import styles from './index.module.scss';
import { useModel } from 'foca';
import { globalSettingModel } from '@models/global-setting.model';
import { Decimal } from 'turbos-clmm-sdk';
import Input from '@ui/input';

const SelectSlippage: FC = () => {
  const [showSetting, setShowSetting] = useState(false);
  const overlay = showSetting ? <Setting /> : <span>Change Slippage Tolerance</span>;

  return (
    <Tooltip
      trigger={showSetting ? 'click' : 'hover'}
      showArrow={!showSetting}
      placement="right"
      overlay={overlay}
      onVisibleChange={(visible) => {
        !visible && setShowSetting(false);
      }}
      hasBorder={showSetting}
    >
      <div
        className={styles.button}
        onClick={() => {
          setShowSetting(true);
        }}
      >
        <img src={settingIcon} data-icon />
      </div>
    </Tooltip>
  );
};

const Setting: FC = () => {
  const { slippage, isCustomSlippage: isCustom } = useModel(globalSettingModel);
  const [inputValue, setInputValue] = useState(isCustom ? slippage : '');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = Number(e.target.value) >= 0 ? e.target.value : '0.1';
    setInputValue(value);
    globalSettingModel.setCustomSlippage(Number(value).toString());
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = () => {
    setInputValue(isCustom ? slippage : '');
  };

  return (
    <>
      <div className={styles.setting_title}>Trade Settings</div>
      <div className={styles.setting_top}>
        <img src={settingIcon} data-icon />
        <span>Slippage Tolerance</span>
      </div>

      <div className={styles.setting}>
        <div className={styles.custom}>
          {['Auto', '0.1', '0.5'].map((item) => (
            <span
              key={item}
              className={item === slippage ? styles.selected : ''}
              onClick={() => globalSettingModel.setEnumSlippage(item)}
            >
              {item}
              {Number.isFinite(Number(item)) && '%'}
            </span>
          ))}
        </div>
        <div className={styles.input_wrapper}>
          <div className={styles.label}>Custom:</div>
          <Input
            type="text"
            value={inputValue}
            placeholder="0.1"
            onChange={handleChange}
            onBlur={handleBlur}
            suffix="%"
          />
        </div>
      </div>
      {isCustom && new Decimal(slippage).lte(0) && (
        <div className={styles.error_tip}>Enter a valid number greater than 0</div>
      )}
      {isCustom && new Decimal(slippage).gt(10) && (
        <div className={styles.error_tip}>Your transaction may be frontrun</div>
      )}
    </>
  );
};

export default memo(SelectSlippage);
