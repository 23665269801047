import type { FC } from 'react';
import styles from './cross-chain-tag.module.scss';

export const CrossChainTag: FC<{
  label: string | null | undefined;
  marginLeft?: boolean;
  marginTop?: boolean;
}> = ({ label, marginLeft = false, marginTop = false }) => {
  if (!label) return null;
  return (
    <div
      className={`${styles.wrapper} ${marginLeft ? styles.margin_left : ''} ${
        marginTop ? styles.margin_top : ''
      }`}
    >
      {label}
    </div>
  );
};
