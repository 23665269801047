import type { FC } from 'react';
import Swap from '@components/deepbook-swap';
import styles from './index.module.scss';
import TurbosLink from './turbos-link';

const DeepbookV2Swap: FC = () => {
  return (
    <>
      <div className={styles.native}>Obtain Native USDC on Turbos</div>
      <Swap />

      <div className={styles.step}>
        <p>Step 1: Buy Native USDC </p>
        <p>Step 2: Provide liquidity to earn boosted yields</p>

        <ul className={styles.items}>
          <li>
            <TurbosLink poolId="0x770010854059edf1dd3d49a97f3054c39b870ec708fe2f408e30a8ef4724caef" />
          </li>
          <li>
            <TurbosLink poolId="0x77f786e7bbd5f93f7dc09edbcffd9ea073945564767b65cf605f388328449d50" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default DeepbookV2Swap;
