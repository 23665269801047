import { userRankingModel } from '@models/user-ranking.model';
import { useLoading, useModel } from 'foca';
import { useEffect } from 'react';

export const useUserRanking = () => {
  const userRanking = useModel(userRankingModel);
  const loading = useLoading(userRankingModel.getUserRanking);

  useEffect(() => {
    userRankingModel.getUserRanking();
  }, []);

  return {
    userRanking,
    userRankingLoading: loading,
  };
};
