import { type ChangeEventHandler, useState, type FC } from 'react';
import backIcon from '@assets/images/icons/back.svg';
import styles from './setting-rpc-content.module.scss';
import { config } from '@config';
import { globalSettingModel, type TurbosCurrentRpcNode } from '@models/global-setting.model';
import { useModel } from 'foca';
import checkboxIcon from '@assets/images/icons/checkbox.svg';
import checkboxSelectedIcon from '@assets/images/icons/checkbox-selected.svg';
import { toast } from 'react-toastify';

const rpcKeys: (keyof typeof config.rpc)[] = Object.keys(config.rpc);
const cacheRpc = localStorage.getItem('TURBOS_CURRENT_RPC');

interface OwnProps {
  toggleSelecting(): void;
}

const SettingRpcContent: FC<OwnProps> = ({ toggleSelecting }) => {
  const rpcKey = useModel(globalSettingModel, (state) => state.rpcKey);
  const [customRpc, setCustomRpc] = useState(
    cacheRpc && (JSON.parse(cacheRpc) as TurbosCurrentRpcNode).rpcKey === 'custom'
      ? (JSON.parse(cacheRpc) as TurbosCurrentRpcNode).rpc
      : '',
  );

  const changeRpc = async (key: TurbosCurrentRpcNode['rpcKey']) => {
    if (key !== 'custom' && !config.rpc[key]) {
      toast(
        <div style={{ color: 'red' }}>
          The current node is temporarily unavailable. Please switch to an alternative node to
          ensure uninterrupted service.
        </div>,
        { type: 'error' },
      );
      return;
    }

    globalSettingModel.setRpcKey(key);
    if (key !== 'custom') {
      toggleSelecting();
      localStorage.setItem(
        'TURBOS_CURRENT_RPC',
        JSON.stringify({ rpcKey: key, rpc: config.rpc[key] }),
      );
      toast(`The current node updated! The current node: ${config.rpc[key]}`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const setCustom: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCustomRpc(e.target.value);
  };

  const saveCustom = async () => {
    localStorage.setItem(
      'TURBOS_CURRENT_RPC',
      JSON.stringify({ rpcKey: 'custom', rpc: customRpc }),
    );
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <div className={styles.header}>
        <img src={backIcon} data-icon className={styles.back} onClick={toggleSelecting} />
        RPC Node
      </div>
      <div className={styles.divider}></div>
      <ul className={styles.options}>
        {[...rpcKeys, 'custom'].map((key) => (
          <li key={key} onClick={() => changeRpc(key)}>
            <img src={key === rpcKey ? checkboxSelectedIcon : checkboxIcon} />
            {key.replace(/\_/g, ' ')}
          </li>
        ))}
      </ul>
      {rpcKey === 'custom' ? (
        <div className={styles.custom}>
          <input
            type={'text'}
            placeholder={'Custom RPC URL'}
            onChange={setCustom}
            value={customRpc}
          />
          <button onClick={saveCustom} disabled={!customRpc}>
            Save
          </button>
        </div>
      ) : null}
    </>
  );
};

export default SettingRpcContent;
