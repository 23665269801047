import 'normalize.css';
import '@assets/styles/bootstrap.css';
import '@models/store';
import { FocaProvider } from 'foca';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { config } from '@config';
import { Network } from 'turbos-clmm-sdk';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { routerObjects } from 'src/routers/router';
import { RefreshProvider } from '@hooks/use-refresh';
import { registerSuiSnapWallet } from '@kunalabs-io/sui-snap-wallet';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import { SuiClientProvider, WalletProvider } from '@mysten/dapp-kit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SuiClient } from '@mysten/sui/client';
import { current_turbos_rpc } from '@libs/turbos-sdk';
import { registerStashedWallet } from '@mysten/zksend';

registerSuiSnapWallet();
registerStashedWallet('Turbos Finance', {});
dayjs.extend(LocalizedFormat);

const queryClient = new QueryClient();

createRoot(document.querySelector('#root')!).render(
  <FocaProvider>
    <QueryClientProvider client={queryClient}>
      <SuiClientProvider
        createClient={(name) => {
          return new SuiClient({
            url:
              current_turbos_rpc ||
              (name === 'mainnet'
                ? 'https://fullnode.mainnet.sui.io:443'
                : 'https://fullnode.testnet.sui.io:443'),
          });
        }}
      >
        <WalletProvider preferredWallets={['msafe']}>
          <RefreshProvider>
            <RouterProvider router={createHashRouter(routerObjects)} />
          </RefreshProvider>
        </WalletProvider>
      </SuiClientProvider>
    </QueryClientProvider>
  </FocaProvider>,
);

if (config.sui_network === Network.mainnet && import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://960d0d08ff5646cc889d724a2a0bac7d@o4505089588396032.ingest.sentry.io/4505091200778241',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: config.sui_network,
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
