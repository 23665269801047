import Dialog from '@ui/dialog';
import Search from '@ui/input/search';
import { useBalances } from '@hooks/use-balances';
import { useCoins } from '@hooks/use-coins';
import {
  type FC,
  memo,
  useState,
  type ReactElement,
  cloneElement,
  useEffect,
  useMemo,
  type ReactNode,
} from 'react';
import styles from './index.module.scss';
import { useCopyToClipboard, useToggle } from 'react-use';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { useSearchCoins } from '@hooks/use-seach-coins';
import { Decimal } from 'turbos-clmm-sdk';
import { formatAddress, normalizeSuiAddress } from '@mysten/sui/utils';
import coinTypeCopyIcon from '@assets/images/icons/copy.svg';
import { toast } from 'react-toastify';
import { hideDataConfig } from 'src/configs/hide';
import CoinLogo from '@ui/coin-logo';
import { deepbook } from '@libs/deepbook-v2';
import Warn from '@components/pool-item/warn';
import { formatSuiNetworkAddress } from '@utils/sui-network-address';
import { HotTokenIcon } from '@components/symbol-pair/hot-token-icon';
import type { TurbosCoinItem } from '@models/coin.model';
import { CrossChainTag } from '@components/symbol-pair/cross-chain-tag';

interface OwnProps {
  filterCoins?: string | string[];
  disableCoins?: string | string[];
  onSelected: (coinType: string) => void;
  children: ReactElement;
  title?: string;
  searchEnable?: boolean;
  deepbookActive?: boolean;
  coinType?: string;
}

const SelectToken: FC<OwnProps> = (props) => {
  const { onSelected, children, searchEnable = true, deepbookActive, coinType } = props;
  const [dialogVisible, toggleDialog] = useToggle(false);
  const disableCoins = props.disableCoins ? ([] as string[]).concat(props.disableCoins) : [];
  const filterCoins = props.filterCoins ? ([] as string[]).concat(props.filterCoins) : [];
  const [searchName, setSearchName] = useState('');
  const balances = useBalances();
  let { coins } = useCoins();
  const { searchCoins } = useSearchCoins(coins, searchName);
  const [, copy] = useCopyToClipboard();
  // const [showHotTab, setShowHotTab] = useState(false);
  const [crossChain, setCrossChain] = useState<string | null>();

  let finalCoins: (TurbosCoinItem & { balance: string })[];
  {
    let resultCoins = searchCoins;
    // if (showHotTab) {
    //   resultCoins = resultCoins.filter((coin) => coin.is_hot);
    // }
    if (crossChain !== undefined) {
      resultCoins = resultCoins.filter((coin) => coin.cross_chain === crossChain);
    }
    resultCoins = resultCoins.filter((coin) => {
      if (/^0x([A-Za-z\d]+)::([A-Za-z\d_]+)::([A-Za-z\d_]+)$/.test(searchName)) {
        return true;
      }
      return coin.flag !== 2;
    });
    if (deepbookActive) {
      resultCoins = resultCoins.filter((coin) => deepbook.findCoinByType(coin.type));
    }
    let newResultCoins = resultCoins.map((coin) => {
      const balance =
        balances.find(
          (item) =>
            formatSuiNetworkAddress(item.coinType.toLocaleLowerCase()) ===
            formatSuiNetworkAddress(normalizeSuiAddress(coin.type).toLocaleLowerCase()),
        )?.totalBalance ?? '0';
      return { ...coin, balance };
    });

    if (!deepbookActive) {
      newResultCoins = newResultCoins.filter((coin) => !hideDataConfig.includes(coin.type));
    }
    finalCoins = newResultCoins.sort((coinA, coinB) => {
      const aBalance = new Decimal(coinA.balance).div(10 ** coinA.decimals).toNumber();
      const bBalance = new Decimal(coinB.balance).div(10 ** coinB.decimals).toNumber();
      if (bBalance !== aBalance) {
        return bBalance - aBalance;
      } else if (Number(coinA.flag) === 1) {
        return -1;
      } else if (Number(coinB.flag) === 1) {
        return 1;
      } else if (coinA.flag !== coinB.flag && (coinA.flag === 2 || coinB.flag === 2)) {
        return Number(coinA.flag) - Number(coinB.flag);
      } else {
        return coinA.symbol.localeCompare(coinB.symbol);
      }
    });
  }

  const popularCoins = useMemo(() => {
    if (deepbookActive) {
      return coins.filter((coin) => !!deepbook.findCoinByType(coin.type));
    }
    return coins.filter((coin) => coin.is_popular);
  }, [coins, deepbookActive]);

  useEffect(() => {
    if (deepbookActive && coinType) {
      const coin = deepbook.findCoinByType(coinType);
      if (!coin) {
        onSelected('');
      }
    }

    if (!deepbookActive && coinType) {
      if (hideDataConfig.includes(coinType)) {
        onSelected('');
      }
    }
  }, [deepbookActive, coinType, onSelected]);

  useEffect(() => {
    if (!dialogVisible) {
      setSearchName('');
    }
  }, [dialogVisible]);

  let $tabs: ReactNode = null;
  if (!searchName) {
    $tabs = (
      <>
        {/* <div className={styles.tabs}>
          <div className={showHotTab ? '' : styles.active} onClick={() => setShowHotTab(false)}>
            Default
          </div>
          <div className={showHotTab ? styles.active : ''} onClick={() => setShowHotTab(true)}>
            Hot
            <HotTokenIcon hot tooltip={false} />
          </div>
        </div> */}
        {/* {!showHotTab && */}
        {
          <div className={styles.cross_chain_tags}>
            {(
              [
                [undefined, 'All'],
                [null, 'Native'],
                ['wormhole', 'Wormhole'],
              ] as const
            ).map(([key, label]) => {
              return (
                <div
                  key={label}
                  className={crossChain === key ? styles.active : ''}
                  onClick={() => setCrossChain(key)}
                >
                  {label}
                </div>
              );
            })}
          </div>
        }
      </>
    );
  }

  return (
    <>
      {cloneElement(children, {
        onClick: toggleDialog,
      })}
      <Dialog
        title={props.title || 'Select a token or enter Coin Type'}
        closable
        centered
        visible={dialogVisible}
        width={500}
        className={styles.dialog}
        onClose={toggleDialog}
      >
        {searchEnable && (
          <Search
            value={searchName}
            wrapperClassName={styles.search}
            onChangeText={setSearchName}
          />
        )}
        {popularCoins.length > 0 && (
          <div className={styles.popular}>
            {popularCoins.map((coin) => {
              const disabled = disableCoins.some((item) => item === coin.type);
              return (
                <div
                  className={`${styles.item} ${disabled ? styles.disabled : ''}`}
                  key={coin.type}
                  onClick={() => {
                    if (disabled) return;
                    onSelected(coin.type);
                    toggleDialog();
                  }}
                >
                  <CoinLogo src={coin.logo_url} />
                  <div className={styles.right}>
                    <div className={styles.top}>
                      <span className={styles.symbol}>{coin.symbol}</span>
                      <HotTokenIcon hot={coin.is_hot} className={styles.hot} />
                      <CrossChainTag label={coin.cross_chain} marginLeft />
                    </div>
                    <div className={styles.bottom}>{coin.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!!searchName && !finalCoins.length && (
          <p className={styles.empty}>
            No token found.<br></br> Please try searching with coinType
          </p>
        )}
        {$tabs}
        {finalCoins.length > 0 && (
          <ul>
            {finalCoins.map((coin) => {
              const disabled = disableCoins.some((item) => item === coin.type);
              const filter = filterCoins.some((item) => item === coin.type);
              if (filter) {
                return null;
              }
              const clickEvent = disabled
                ? undefined
                : () => {
                    onSelected(coin.type);
                    toggleDialog();
                  };
              return (
                <li key={coin.type} className={disabled ? styles.disable : ''} onClick={clickEvent}>
                  <CoinLogo src={coin.logo_url} />
                  <div className={styles.content}>
                    <p className={styles.symbol}>
                      {coin.symbol}
                      <span className={styles.verified}>
                        {coin.flag === 2 ? <Warn /> : null}
                        {coin.flag === 1 ? <Warn type="verified" /> : null}
                      </span>
                      <HotTokenIcon hot={coin.is_hot} />
                      <CrossChainTag label={coin.cross_chain} marginLeft />
                    </p>
                    <p>{coin.name}</p>
                  </div>
                  <div className={styles.balance}>
                    {bigNumberToReadable(coin.balance, coin.decimals)}
                    <br />
                    <span
                      onClick={(event) => {
                        event.stopPropagation();
                        copy(coin.type);
                        toast('Address is copied');
                      }}
                    >
                      {formatAddress(coin.type)} <img src={coinTypeCopyIcon} />
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </Dialog>
    </>
  );
};

export default memo(SelectToken);
