import { PureComponent } from 'react';
import styles from './index.module.scss';

interface OwnProps<T extends string> {
  aLabel: string;
  aValue: T;
  bLabel: string;
  bValue: T;
  selected: string;
  onSelected(value: T): void;
  /**
   * Defaults `highlight`
   */
  theme?: 'highlight' | 'normal';
  size: 'default' | 'small';
  className?: string;
}

class Switch<T extends string> extends PureComponent<OwnProps<T>> {
  override render() {
    const {
      aLabel,
      aValue,
      bLabel,
      bValue,
      selected,
      onSelected,
      size,
      className,
      theme = 'highlight',
    } = this.props;

    return (
      <ul
        className={`${styles.switch} ${styles[`theme_${theme}`]} ${
          styles[`size_${size}`]
        } ${className}`}
      >
        <li className={selected === aValue ? styles.active : ''} onClick={() => onSelected(aValue)}>
          {aLabel}
        </li>
        <li className={selected === bValue ? styles.active : ''} onClick={() => onSelected(bValue)}>
          {bLabel}
        </li>
      </ul>
    );
  }
}

export default Switch;
