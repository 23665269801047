import Loading from '@ui/loading';
import { useCoinStats } from '@hooks/use-coin-stats';
import { useCoins } from '@hooks/use-coins';
import type { TurboCoinStatsItem } from '@models/coin-stats.model';
import { type FC, memo, useState } from 'react';
import styles from './hot-token-ranking.module.scss';
import Tooltip from '@ui/tooltip';
import { swapCoinModel } from '@models/swap-coin.model';
import Switch from '@ui/switch';
import { useModel } from 'foca';
import CoinLogo from '@ui/coin-logo';

type SumKeys = keyof TurboCoinStatsItem['_sum'];

const HotTokenRanking: FC = () => {
  const { coins, loading: coinsLoading } = useCoins();
  const { stats, loading: statsLoading } = useCoinStats(24);
  const [tab, setTab] = useState<SumKeys>('trade_amount');
  const loading = (coinsLoading && !coins.length) || (statsLoading && !stats.length);
  const { coinTypeA: sellCoinType } = useModel(swapCoinModel);

  const sortedStats = [...new Set(stats)]
    .filter((item) => !['usdc', 'usdt', 'weth'].includes(item.coin_symbol.toLowerCase()))
    .sort((a, b) => b._sum[tab] - a._sum[tab]);

  return (
    <>
      <div>By</div>
      <Switch<SumKeys>
        aLabel="Transactions"
        aValue="trade_amount"
        bLabel="Volume"
        bValue="volume"
        selected={tab}
        onSelected={setTab}
        size="default"
        className={styles.switch}
      />
      {loading ? (
        <Loading size={30} />
      ) : (
        sortedStats.map((item, index) => {
          const coin = coins.find(
            (coin) => coin.type.toLocaleLowerCase() === item.coin_type.toLocaleLowerCase(),
          );
          return (
            <Tooltip overlay="Click to buy" showArrow placement="top" key={item.coin_symbol}>
              <div
                className={styles.coin}
                onClick={() =>
                  item.coin_type !== sellCoinType && swapCoinModel.updateB(item.coin_type)
                }
              >
                <span>#{index + 1}</span>
                <CoinLogo src={coin?.logo_url} />
                <span>{item.coin_symbol}</span>
              </div>
            </Tooltip>
          );
        })
      )}
    </>
  );
};

export default memo(HotTokenRanking);
