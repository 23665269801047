import { type FC, memo } from 'react';
import pythIcon from '@assets/images/icons/pyth-logo.svg';
import turbosIcon from '@assets/images/icons/turbos.svg';
import coingeckoIcon from '@assets/images/icons/coingecko.png';
import styles from './pyth-price.module.scss';
import Tooltip from '@ui/tooltip';
import Loading from '@ui/loading';
import { usePrice } from '@hooks/use-price';
import { bigNumberToReadable } from '@utils/big-number-to-readable';

interface OwnProps {
  coinType: string;
}

const PythPrice: FC<OwnProps> = ({ coinType }) => {
  const coinPrice = usePrice(coinType);
  if (!coinPrice || (coinPrice && !coinPrice.price)) return <div></div>;

  let source = 'Turbos Pool Price';
  let icon = turbosIcon;

  if (coinPrice.source === 'pyth') {
    source = 'Pyth Network';
    icon = pythIcon;
  } else if (coinPrice.source === 'coingecko') {
    source = 'Coingecko Network';
    icon = coingeckoIcon;
  }

  return (
    <div className={styles.wrapper}>
      <Tooltip overlay={`Data Source: ${source}`} placement="top" mouseEnterDelay={0.3}>
        <div className={styles.pyth}>
          <img src={icon} data-icon />
          &nbsp;&nbsp;
          {coinPrice.price === void 0 && <Loading size={20} />}
          {coinPrice.price !== void 0 && <span>${bigNumberToReadable(coinPrice.price, 0)}</span>}
        </div>
      </Tooltip>

      {coinPrice.percentage ? (
        <Tooltip overlay="24-hour Price Change" placement="top" mouseEnterDelay={0.3}>
          <span className={coinPrice.percentage >= 0 ? styles.green : styles.red}>
            {coinPrice.percentage >= 0 ? '+' : ''}
            {coinPrice.percentage.toFixed(2)}%
          </span>
        </Tooltip>
      ) : (
        <></>
      )}
    </div>
  );
};

export default memo(PythPrice);
