import { type FC, memo, useEffect } from 'react';
import walletIcon from '@assets/images/icons/wallet.png';
import styles from './wallet-unconnect.module.scss';
import { walletConnectModel } from '@models/wallet-connect.model';
import { useConnectWallet } from '@mysten/dapp-kit';
import { MSafeWallet } from '@msafe/sui-wallet';
import { turbosSdk } from '@libs/turbos-sdk';
import { config } from '@config';

const WalletUnconnect: FC = () => {
  const { mutate: connect } = useConnectWallet();
  const inMSafeWallet = MSafeWallet.inMSafeWallet();

  const connectMSafeWallet = () => {
    connect({
      // @ts-ignore
      wallet: new MSafeWallet(
        'turbos',
        // @ts-ignore
        turbosSdk.provider,
        `sui:${config.sui_network}`,
      ),
      silent: true,
    });
  };

  useEffect(() => {
    if (inMSafeWallet) {
      connect({
        // @ts-ignore
        wallet: new MSafeWallet(
          'turbos',
          // @ts-ignore
          turbosSdk.provider,
          `sui:${config.sui_network}`,
        ),
        silent: true,
      });
    }
  }, []);

  return (
    <>
      <div
        className={styles.button}
        key="not-connected"
        onClick={inMSafeWallet ? connectMSafeWallet : walletConnectModel.toggleDialog}
      >
        <img src={walletIcon} className={styles.wallet_icon} />
        <span>Connect Wallet</span>
      </div>
    </>
  );
};

export default memo(WalletUnconnect);
