import http from '@libs/http';
import { defineModel } from 'foca';

const initialState: {
  referralCode: string;
} = {
  referralCode: '',
};

export const referralLinkModel = defineModel('referral-link', {
  initialState: initialState,
  reducers: {
    _updateCode(state, code: string) {
      state.referralCode = code;
    },
  },
  methods: {
    async invite(code: string, walletAddress: string) {
      await http.post('/referral/invite', {
        code,
        wallet_address: walletAddress,
      });
    },
  },
  events: {
    onInit() {
      const searchParams = new URLSearchParams(location.hash.split('?')[1]);
      const code = searchParams.get('referral');
      if (code) {
        this._updateCode(code);
      }
    },
  },
});
