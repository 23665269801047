import { useCoinChange24h } from '@hooks/use-coin-change-24h';
import Loading from '@ui/loading';
import { type FC, memo } from 'react';
import styles from './change-24h.module.scss';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import CoinLogo from '@ui/coin-logo';
import { useCoins } from '@hooks/use-coins';

const Change24h: FC = () => {
  const { coins, loading } = useCoinChange24h();
  const turbosCoins = useCoins();

  if (loading) {
    return <Loading size={42} centered />;
  }

  if (!coins.length) {
    return <p>No Data</p>;
  }

  const newCoins = [...coins];
  return (
    <>
      {newCoins
        .sort(
          (coinA, coinB) => coinB.price_change_percentage_24h - coinA.price_change_percentage_24h,
        )
        .filter((coin) => !['ethereum'].includes(coin.id))
        .map((coin, index) => {
          const isNegative = coin.price_change_percentage_24h < 0;
          const turbosCoin = turbosCoins.coins.find(
            (turbosCoin) => turbosCoin.coingecko_id === coin.id,
          );
          return (
            <div className={styles.coin} key={coin.symbol}>
              <span>#{index + 1}</span>
              <CoinLogo src={turbosCoin?.logo_url || coin.image} />
              <span>{coin.symbol}</span>
              <span>${bigNumberToReadable(coin.current_price, 0)}</span>
              <span className={isNegative ? styles.negative : ''}>
                {coin.price_change_percentage_24h.toFixed(2)}%
              </span>
            </div>
          );
        })}
    </>
  );
};

export default memo(Change24h);
