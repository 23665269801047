import { useLoading, useModel } from 'foca';
import { useEffect } from 'react';
import { coinChange24hModel } from '@models/coin-change-24h.model';
import { useCoins } from './use-coins';

export const useCoinChange24h = () => {
  const { coins } = useCoins();
  const changedCoins = useModel(coinChange24hModel);
  const loading = useLoading(coinChange24hModel.fetch);

  useEffect(() => {
    const coingeckoIds = coins.map((coin) => coin.coingecko_id).filter(Boolean);
    if (!changedCoins.length && coins.length) {
      coinChange24hModel.fetch(coingeckoIds);
    }
  }, [coins]);

  return { coins: changedCoins, loading };
};
