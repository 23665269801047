import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { type FC, memo, useMemo } from 'react';
import { useCoins } from '@hooks/use-coins';

const CoinPath: FC<{
  bestRoutes: BestSwapRouterType[] | undefined;
  isDeepbook: boolean;
  isAftermath: boolean;
}> = ({ bestRoutes = [], isDeepbook, isAftermath }) => {
  const { coins } = useCoins();
  const paths = useMemo(() => {
    const paths: string[] = [];
    for (const { pool, coinTypeA, coinTypeB } of bestRoutes) {
      if (isDeepbook) {
        paths.push(coinTypeA || '');
        paths.push(coinTypeB || '');
      } else {
        if (!pool) {
          return [];
        }
        const coinA = coins.find((coin) => pool.coin_type_a === coin.type);
        const coinB = coins.find((coin) => pool.coin_type_b === coin.type);
        const symbolA = coinA ? coinA.symbol : '';
        const symbolB = coinB ? coinB.symbol : '';
        paths.push(pool.a_to_b ? symbolA : symbolB);
        paths.push(pool.a_to_b ? symbolB : symbolA);
      }
    }
    return [...new Set(paths)];
  }, [bestRoutes, coins, isDeepbook, isAftermath]);

  return (
    <>
      <span>Swapping Through</span>
      <span>{isAftermath ? 'Aftermath' : paths.join(' -> ')}</span>
    </>
  );
};

export default memo(CoinPath);
