import Tooltip from '@ui/tooltip';
import { useState, type FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getHeaderMenuIcon, type Menus } from 'src/routers/menu';
import styles from './menu-list.module.scss';
import upIcon from '@assets/images/icons/limit-up.svg';
import downIcon from '@assets/images/icons/limit-down.svg';
import MenuChildren from './menu-children';

interface OwnProps {
  menu: Menus;
}

const MenuList: FC<OwnProps> = ({ menu }) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const external = menu.path.indexOf('http') === 0 || menu.path.indexOf('https') === 0;
  const isActive = location.pathname.indexOf(menu.path) === 0;
  const $img = <img src={getHeaderMenuIcon(menu.key + (isActive ? '-active' : ''))} data-icon />;

  if (external || menu.target === '_blank') {
    return (
      <div className={styles.menu_a}>
        <a href={menu.target ? '#' + menu.path : menu.path} target={menu.target || '_self'}>
          {$img}
          <span>{menu.name}</span>
        </a>
      </div>
    );
  }

  if (menu.children && menu.children.length > 0) {
    return (
      <>
        <Tooltip
          showArrow={false}
          overlay={<MenuChildren menu={menu} onChangeVisible={setVisible} />}
          overlayClassName={styles.overlay}
          placement="bottom"
          visible={visible}
          onVisibleChange={setVisible}
          trigger={['hover']}
        >
          <div className={`${styles.menu_a} ${styles.menu_a_pc}`}>
            {menu.visible ? (
              <div className={`${styles.a} ${isActive ? styles.active_menu : ''}`}>
                {$img}
                <span>{menu.name}</span>
                <img src={visible ? upIcon : downIcon} className={styles.icon} />
              </div>
            ) : (
              <Link
                to={menu.visible ? '' : menu.path}
                className={isActive ? styles.active_menu : ''}
              >
                {$img}
                <span>{menu.name}</span>
                <img src={visible ? upIcon : downIcon} className={styles.icon} />
              </Link>
            )}
          </div>
        </Tooltip>

        <div
          className={`${styles.menu_a} ${styles.menu_a_mobile}`}
          onClick={() => setVisible(!visible)}
        >
          <Link to={'#'} className={isActive ? styles.active_menu : ''}>
            {$img}
            <span>{menu.name}</span>
            <img src={visible ? upIcon : downIcon} className={styles.icon} />
          </Link>
        </div>
        <div className={styles.menu_a_mobile} style={{ display: visible ? '' : 'none' }}>
          <MenuChildren menu={menu} onChangeVisible={setVisible} />
        </div>
      </>
    );
  }

  return (
    <div className={styles.menu_a}>
      <Link to={menu.path} className={isActive ? styles.active_menu : ''}>
        {$img}
        <span>{menu.name}</span>
      </Link>
    </div>
  );
};

export default MenuList;
