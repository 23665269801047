import { type FC, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getHeaderMenuIcon, type Menus } from 'src/routers/menu';
import styles from './menu-children.module.scss';

interface OwnProps {
  menu: Menus;
  onChangeVisible(value: boolean): void;
}

const MenuChildren: FC<OwnProps> = ({ menu, onChangeVisible }) => {
  const navigate = useNavigate();
  const touchEvent = (path: string) => {
    navigate(path);
  };
  return (
    <div className={`${styles.items} ${styles.items_mobile}`}>
      {menu.children?.map((item) => {
        const external = item.path.indexOf('http') === 0;
        const isActive = location.pathname.indexOf(item.path) === 0;
        const $img = (
          <img src={getHeaderMenuIcon(item.key + (isActive ? '-active' : ''))} data-icon />
        );

        if (external || item.target === '_blank') {
          return (
            <a
              href={external ? item.path : item.target ? '#' + item.path : item.path}
              key={item.key}
              target={item.target || '_self'}
            >
              {$img}
              <span>{item.name}</span>
            </a>
          );
        }

        return (
          <Link
            to={item.path}
            key={item.key}
            onClick={() => onChangeVisible(false)}
            onTouchStart={() => touchEvent(item.path)}
          >
            {$img}
            <span>{item.name}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default memo(MenuChildren);
