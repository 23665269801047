export const deepbookConfigs = {
  devnet: {
    pools: [
      {
        clob: '0xdfa895c7dd7b5d1b79f3ba9be77113b77e5f6277e5458f1e32db26498f0997d6',
        type: '0xdee9::clob_v2::Pool<0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::weth::WETH, 0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::usdt::USDT>',
        priceDecimals: 2,
        amountDecimals: 3,
        tickSize: 1000000,
      },
    ],
    caps: [],
    tokens: [
      {
        type: '0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::usdt::USDT',
        symbol: 'USDT',
        decimals: 7,
      },
      {
        type: '0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::weth::WETH',
        symbol: 'WETH',
        decimals: 8,
      },
    ],
  },
  testnet: {
    pools: [
      {
        clob: '0xdfa895c7dd7b5d1b79f3ba9be77113b77e5f6277e5458f1e32db26498f0997d6',
        type: '0xdee9::clob_v2::Pool<0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::weth::WETH, 0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::usdt::USDT>',
        priceDecimals: 2,
        amountDecimals: 3,
        tickSize: 1000000,
      },
    ],
    caps: [],
    tokens: [
      {
        type: '0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::usdt::USDT',
        symbol: 'USDT',
        decimals: 7,
      },
      {
        type: '0x219d80b1be5d586ff3bdbfeaf4d051ec721442c3a6498a3222773c6945a73d9f::weth::WETH',
        symbol: 'WETH',
        decimals: 8,
      },
    ],
  },
  mainnet: {
    pools: [
      // {
      //   clob: '0x4405b50d791fd3346754e8171aaab6bc2ed26c2c46efdd033c14b30ae507ac33',
      //   type: '0xdee9::clob_v2::Pool<0x2::sui::SUI, 0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN>',
      //   priceDecimals: 2,
      //   amountDecimals: 3,
      //   tickSize: 100,
      // },
      // {
      //   clob: '0x6e417ee1c12ad5f2600a66bc80c7bd52ff3cb7c072d508700d17cf1325324527',
      //   type: '0xdee9::clob_v2::Pool<0xaf8cd5edc19c4512f4259f0bee101a40d41ebed738ade5874359610ef8eeced5::coin::COIN, 0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN>',
      //   priceDecimals: 2,
      //   amountDecimals: 3,
      //   tickSize: 1000000,
      // },
      // {
      //   clob: '0xd1f0a9baacc1864ab19534e2d4c5d6c14f2e071a1f075e8e7f9d51f2c17dc238',
      //   type: '0xdee9::clob_v2::Pool<0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN, 0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN>',
      //   priceDecimals: 2,
      //   amountDecimals: 3,
      //   tickSize: 100000,
      // },
      {
        clob: '0x39f2f7c126189b9b5cda6b770ab34031dde22307d19842e95ab576873c77fa14',
        type: '0xdee9::clob_v2::Pool<0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN, 0xdba34672e30cb065b1f93e3ab55318768fd6fef66c15942c9f7cb846e2f900e7::usdc::USDC>',
        priceDecimals: 2,
        amountDecimals: 3,
        tickSize: 100000,
      },
    ],
    caps: [],
    tokens: [
      // {
      //   type: '0x2::sui::SUI',
      //   symbol: 'SUI',
      //   decimals: 9,
      // },
      {
        type: '0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN',
        symbol: 'USDC',
        decimals: 6,
      },
      // {
      //   type: '0xaf8cd5edc19c4512f4259f0bee101a40d41ebed738ade5874359610ef8eeced5::coin::COIN',
      //   symbol: 'WETH',
      //   decimals: 8,
      // },
      // {
      //   type: '0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN',
      //   symbol: 'USDT',
      //   decimals: 6,
      // },
      {
        type: '0xdba34672e30cb065b1f93e3ab55318768fd6fef66c15942c9f7cb846e2f900e7::usdc::USDC',
        symbol: 'USDC',
        decimals: 6,
      },
    ],
  },
};
