import { type FC } from 'react';
import Swap from '@components/swap';
import FixedFooter from '@components/fixed-footer';
import HotTokenRanking from '@components/fixed-footer/hot-token-ranking';
import TradingLeaderboard from '@components/fixed-footer/trading-leaderboard';
import Change24h from '@components/fixed-footer/change-24h';
import { Top } from '@components/top/top';
// import TodayRewards from './components/today-rewards';
import Say from '@components/say';
import SwapNative from '@components/swap/swap-native';
const Trade: FC = () => {
  return (
    <>
      <Top />
      <Swap />
      {/* <TodayRewards /> */}
      <SwapNative />
      <Say />
      <FixedFooter
        defaultCollapsed={true}
        configs={[
          {
            key: 'hot',
            label: '24h Hot Tokens',
            element: <HotTokenRanking />,
          },
          {
            key: 'change-24h',
            label: 'Change (24h)',
            element: <Change24h />,
          },
          {
            key: 'trade',
            label: 'Top Traders',
            element: <TradingLeaderboard />,
          },
        ]}
      />
    </>
  );
};

export default Trade;
