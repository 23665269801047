import http from 'src/services/http';
import { defineModel } from 'foca';
import { uniq } from 'lodash-es';

const initialState: {
  coingeckoIds: string[];
  prices: Record<string, string | undefined>;
  /**
   * changed percentage in 24 hours
   */
  percent24h: Record<string, number | undefined>;
  cacheTime: Record<string, number>;
} = {
  coingeckoIds: [],
  prices: {},
  percent24h: {},
  cacheTime: {},
};

export const coingeckoModel = defineModel('coingecko', {
  initialState,
  reducers: {
    collectCoin(state, coin: string) {
      state.coingeckoIds.push(coin);
    },
    collectCoins(state, coins: string[]) {
      state.coingeckoIds.push(...coins);
    },
    _resetCoins(state) {
      state.coingeckoIds = [];
    },
  },
  methods: {
    async _getPrice(coingeckoIds: string[]) {
      const result = await http.get<Record<string, { usd: number; usd_24h_change: number }>>(
        'https://api.coingecko.com/api/v3/simple/price',
        {
          params: {
            ids: coingeckoIds.join(','),
            vs_currencies: 'usd',
            include_24hr_change: true,
          },
        },
      );
      this.setState((state) => {
        coingeckoIds.forEach((coingeckoId) => {
          state.cacheTime[coingeckoId] = Date.now();
          state.prices[coingeckoId] = result[coingeckoId]?.usd.toString();
          state.percent24h[coingeckoId] = result[coingeckoId]?.usd_24h_change;
        });
      });
    },
    async _timer() {
      const timeOut = setTimeout(async () => {
        const coingeckoIds = uniq(this.state.coingeckoIds).filter(
          (coingeckoId) =>
            !this.state.cacheTime[coingeckoId] ||
            this.state.cacheTime[coingeckoId]! + 60_000 < Date.now(),
        );
        if (coingeckoIds.length) {
          try {
            this._resetCoins();
            await this._getPrice(coingeckoIds);
          } finally {
            clearTimeout(timeOut);
            this._timer();
          }
        } else {
          clearTimeout(timeOut);
          this._timer();
        }
      }, 150);
    },
  },
  events: {
    onInit() {
      this._timer();
    },
  },
});
