import { useCoin } from '@hooks/use-coins';
import type { BestSwapRouterType } from '@hooks/use-swap-route';
import { type FC, memo } from 'react';
import { usePoolPriceAndLiquidity } from '@hooks/use-pool-price-and-liquidity';
import { Decimal } from 'turbos-clmm-sdk';
import { formatDigitUnit } from '@utils/format-digit-unit';
import { useAftermathPrice } from '@hooks/use-aftermath-price';

interface OwnProps {
  bestRoutes: BestSwapRouterType[] | undefined;
  amountIn?: string;
  className?: string;
  isAftermath?: boolean;
  coinAType?: string;
}

const CoinAPrice: FC<OwnProps> = ({ bestRoutes, amountIn, className, isAftermath, coinAType }) => {
  const pool = bestRoutes?.[0]?.pool;
  const coinA = useCoin(pool?.coin_type_a);
  const coinB = useCoin(pool?.coin_type_b);
  const PPAL = usePoolPriceAndLiquidity(pool);
  const aftermathACoin = useCoin(isAftermath ? coinAType : undefined);
  const aftermathAPrice = useAftermathPrice(isAftermath ? coinAType : undefined);

  if (isAftermath && aftermathACoin && aftermathAPrice && amountIn) {
    const value = new Decimal(aftermathAPrice).mul(amountIn).div(10 ** aftermathACoin.decimals);
    return <div className={className}>${formatDigitUnit(value)}</div>;
  }

  if (!PPAL || !amountIn || !pool) return null;

  const price = pool.a_to_b ? PPAL.priceA : PPAL.priceB;
  const decimals = pool.a_to_b ? coinA!.decimals : coinB!.decimals;

  return (
    <div className={className}>
      ${formatDigitUnit(new Decimal(price).mul(amountIn).div(10 ** decimals))}
    </div>
  );
};

export default memo(CoinAPrice);
