import { useMemo } from 'react';
import { Decimal, MAX_TICK_INDEX, MIN_TICK_INDEX } from 'turbos-clmm-sdk';
import { usePoolPriceAndLiquidity } from './use-pool-price-and-liquidity';
import { useSqrtPriceToPrice } from './use-sqrt-price-to-price';
import { type BestSwapRouterType } from './use-swap-route';

export const useImpact = (bestRoutes: BestSwapRouterType[] | undefined): Decimal | undefined => {
  const firstRoute = bestRoutes ? bestRoutes[0]! : undefined;
  const lastRoute = bestRoutes ? bestRoutes[bestRoutes.length - 1]! : undefined;

  const firstCurrentPPAL = usePoolPriceAndLiquidity(firstRoute ? firstRoute.pool : undefined);
  const firstNextPrice = useSqrtPriceToPrice(
    firstRoute ? firstRoute.swapResult!.sqrt_price : undefined,
    firstRoute ? firstRoute.pool!.coin_type_a : undefined,
    firstRoute ? firstRoute.pool!.coin_type_b : undefined,
  );
  const lastCurrentPPAL = usePoolPriceAndLiquidity(lastRoute ? lastRoute.pool : undefined);
  const lastNextPrice = useSqrtPriceToPrice(
    lastRoute ? lastRoute.swapResult!.sqrt_price : undefined,
    lastRoute ? lastRoute.pool!.coin_type_a : undefined,
    lastRoute ? lastRoute.pool!.coin_type_b : undefined,
  );

  const firstImpact = useMemo(() => {
    if (!firstRoute) return;
    if (firstRoute.nextTickIndex >= MAX_TICK_INDEX || firstRoute.nextTickIndex <= MIN_TICK_INDEX) {
      return new Decimal('100');
    }
    if (!firstCurrentPPAL || !firstNextPrice) return;
    const impact = new Decimal(firstCurrentPPAL.currentPrice)
      .minus(firstNextPrice)
      .div(firstCurrentPPAL.currentPrice)
      .mul(100);
    return firstRoute.pool!.a_to_b ? impact : impact.neg();
  }, [firstRoute, firstCurrentPPAL, firstNextPrice]);

  const lastImpact = useMemo(() => {
    if (!lastRoute) return;

    if (lastRoute.nextTickIndex >= MAX_TICK_INDEX || lastRoute.nextTickIndex <= MIN_TICK_INDEX) {
      return new Decimal('100');
    }
    if (!lastCurrentPPAL || !lastNextPrice) return;
    const impact = new Decimal(lastCurrentPPAL.currentPrice)
      .minus(lastNextPrice)
      .div(lastCurrentPPAL.currentPrice)
      .mul(100);
    return lastRoute.pool!.a_to_b ? impact : impact.neg();
  }, [lastRoute, lastCurrentPPAL, lastNextPrice]);

  const impact =
    firstImpact && lastImpact
      ? firstImpact.greaterThan(lastImpact)
        ? firstImpact
        : lastImpact
      : undefined;

  return impact;
  // const { coins } = useCoins();
  // return useMemo(() => {
  //   if (!bestRoutes) return;
  //   let impact: undefined | Decimal;
  //   bestRoutes.forEach((route) => {
  //     if (!route.pool) {
  //       return;
  //     }
  //     const coinA = coins.find((coin) => coin.type === route.pool!.coin_type_a);
  //     const coinB = coins.find((coin) => coin.type === route.pool!.coin_type_b);
  //     if (!coinA || !coinB) {
  //       return;
  //     }
  //     const currentPrice = turbosSdk.math.tickIndexToPrice(
  //       turbosSdk.math.bitsToNumber(route.swapResult!.tick_pre_index.bits),
  //       coinA.decimals,
  //       coinB.decimals,
  //     );

  //     const nextPrice = turbosSdk.math.tickIndexToPrice(
  //       turbosSdk.math.bitsToNumber(route.swapResult!.tick_current_index.bits),
  //       coinA.decimals,
  //       coinB.decimals,
  //     );
  //     const newImpact = nextPrice.minus(currentPrice).div(currentPrice).mul(100);
  //     impact = newImpact.greaterThan(impact || 0) ? newImpact : impact;
  //   });
  //   return impact;
  // }, [bestRoutes, coins]);
};
