import { useCoin } from './use-coins';
import { Decimal } from 'turbos-clmm-sdk';
import { SwapPriceStatus } from '@constants/swap-price-status';
import { useMemo } from 'react';
import { usePrice } from './use-price';
import type { RouterCompleteTradeRoute } from 'non-restrictive-aftermath-ts-sdk';
import { useAftermathPrice } from './use-aftermath-price';

/**
 * - false     : Never show status
 * - undefined : Route doesn't exist or data is fetching
 */
export const useSwapPriceStatus = (
  coinTypeA: string | undefined,
  coinTypeB: string | undefined,
  amountIn: string | undefined,
  amountOut: string | undefined,
  isAftermath: boolean,
  aftermathRoute: RouterCompleteTradeRoute | undefined,
) => {
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);
  const coinAPrice = usePrice(coinTypeA || '');
  const coinBPrice = usePrice(coinTypeB || '');
  const coinAAftermathPrice = useAftermathPrice(coinTypeA);
  const coinBAftermathPrice = useAftermathPrice(coinTypeB);

  return useMemo(() => {
    if (!coinA || !coinB || !amountIn) return;

    if (isAftermath) {
      if (!amountIn || !coinAAftermathPrice || !coinBAftermathPrice || !aftermathRoute) return;
    } else {
      if (!amountOut || !coinBPrice?.price || !coinAPrice?.price) return;
    }

    let current_price, price;

    if (aftermathRoute && coinAAftermathPrice && coinBAftermathPrice) {
      current_price = new Decimal(aftermathRoute.coinOut.amount.toString())
        .mul(10 ** coinA.decimals)
        .div(new Decimal(amountIn).mul(10 ** coinB.decimals));

      price = new Decimal(coinAAftermathPrice).div(coinBAftermathPrice);
    } else if (amountOut && coinAPrice && coinBPrice) {
      current_price = new Decimal(amountOut)
        .mul(10 ** coinA.decimals)
        .div(new Decimal(amountIn).mul(10 ** coinB.decimals));

      price = new Decimal(coinAPrice.price).div(coinBPrice?.price);
    }

    if (!current_price || !price) return;

    const diff = new Decimal(current_price).minus(price);

    if (diff.greaterThan(0)) {
      return SwapPriceStatus.great;
    }

    const priceRatio = diff.dividedBy(price);
    if (priceRatio.greaterThan(-0.02) && priceRatio.lessThan(0)) {
      return SwapPriceStatus.fair;
    }

    if (priceRatio.lessThan(0.02) && priceRatio.greaterThan(0)) {
      return SwapPriceStatus.fair;
    }

    return SwapPriceStatus.warn;
  }, [coinA, coinB, amountIn, amountOut, coinAPrice?.price, coinBPrice?.price]);
};
