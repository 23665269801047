import { type FC, memo } from 'react';
import styles from './price-status.module.scss';
import { getSwapPriceIcon, SwapPriceStatus, swapPriceTips } from '@constants/swap-price-status';

interface OwnProps {
  status: SwapPriceStatus | undefined | false;
  show: boolean;
  onClick: () => void;
}

const PriceStatus: FC<OwnProps> = ({ show, onClick, status: priceStatus }) => {
  if (priceStatus === void 0 || priceStatus === false) return <span />;

  return (
    <div
      className={`${styles.price_button} ${styles['status-' + priceStatus]} ${
        show ? styles.show : ''
      }`}
      onClick={onClick}
    >
      <img src={getSwapPriceIcon(SwapPriceStatus[priceStatus])} data-icon />
      <span>{swapPriceTips[priceStatus]}</span>
      <img src={getSwapPriceIcon(SwapPriceStatus[priceStatus] + '-arrow')} data-icon />
    </div>
  );
};

export default memo(PriceStatus);
