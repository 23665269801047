import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Network } from 'turbos-clmm-sdk';

dayjs.extend(utc);

export const prixConfigs = {
  [Network.devnet]: {
    PakcageId: '0x395a73f092891a6bfbc690d46da2155ca479e69c9823eecb9ae01c7ce273f037',
    Claim: '0x5cf6cbdf6fb6cfc661fed586165bed9e4bf6440984a3e4972ecfd5cf97b1b34a',
    turbosCoinType:
      '0x541826891e877178df82f2df2996599618a259e719ef54a8e1969211c609cd21::turbos::TURBOS',
    startTime: dayjs.utc('2023-09-21T04:00:00Z').unix(),
    turbos_usdc: '0x9d5b9fe63938c735689358a45165d1b8a491199fde0c18ddf8ae8bf34cab339d',
    turbos_sui: '0x96030fb3a5dc1eeff1c2ed69c8787dcc24d9ed956fced353fdd3c169178a113b',
    dayTimestamp: 1 * 60 * 60 * 3,
    claimLongTimestamp: 1 * 60 * 60 * 1,
  },
  [Network.mainnet]: {
    PakcageId: '0x02fe4933f4521250e55a15441096d1d4a38a7311195b0bca126fc0138c1f5a97',
    Claim: '0x0c822cec42f7ca703696b4232f1c47b348330c23580172a29aab465bc071d894',
    turbosCoinType:
      '0x5d1f47ea69bb0de31c313d7acf89b890dbb8991ea8e03c6c355171f84bb1ba4a::turbos::TURBOS',
    startTime: dayjs.utc('2023-09-26T12:00:00Z').unix(),
    turbos_usdc: '0x51ee9f5e33c1d7b38b197a09acb17ef0027e83e6d0b3c0f6466855398e4c1cba',
    turbos_sui: '0x2c6fc12bf0d093b5391e7c0fed7e044d52bc14eb29f6352a3fb358e33e80729e',
    dayTimestamp: 1 * 60 * 60 * 24 * 7,
    claimLongTimestamp: 1 * 60 * 60 * 24,
  },
};
