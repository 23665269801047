import { defineModel } from 'foca';

export const privacyModel = defineModel('privacy', {
  initialState: {
    agree: false,
  },
  reducers: {
    agree(state) {
      state.agree = true;
    },
  },
});
