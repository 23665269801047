import { type FC, memo, useState, useCallback, useEffect } from 'react';
import SwapHeader from './swap-header';
import styles from './index.module.scss';
import SwapSection from './swap-section';
import { useModel } from 'foca';
import { swapCoinModel } from '@models/swap-coin.model';
import SwapExchange from './swap-exchange';
import SwapSubmit from './swap-submit';
import { useSwapRoute } from '@hooks/use-swap-route';
import RouteInfo from '../swap-route';
import CoinAPrice from './coin-a-price';
import CoinBPrice from './coin-b-price';
import PythPrice from './pyth-price';
import { useHash } from 'react-use';
import { findLocationSearch } from '@utils/location-search';

const Swap: FC = () => {
  const { coinTypeA, coinTypeB } = useModel(swapCoinModel);
  const [amount, setAmount] = useState<string>();
  const [amountSpecifiedIsInput, setAmountSpecifiedIsInput] = useState(false);
  const {
    fetchingBestRoute,
    bestRoute,
    amountIn,
    amountOut,
    insufficientLiquidity,
    aftermathRoute,
    isAftermath,
  } = useSwapRoute(amount, amountSpecifiedIsInput);
  const hash = useHash();

  const handleTransacted = useCallback(() => {
    setAmount(undefined);
    // TO clear both input value
    setAmountSpecifiedIsInput((prev) => !prev);
  }, []);

  const handleSetInput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(true);
  }, []);

  const handleSetOutput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(false);
  }, []);

  const handleExchange = useCallback(() => {
    swapCoinModel.exchange();
    setAmountSpecifiedIsInput((prev) => !prev);
  }, []);

  useEffect(() => {
    if (coinTypeA && coinTypeB) {
      window.location.hash = `/trade?input=${coinTypeA}&output=${coinTypeB}`;
    }
  }, [coinTypeA, coinTypeB]);

  useEffect(() => {
    const _hash = hash[0].replace(/.*\?/, '');
    const input = findLocationSearch(_hash, 'input');
    const output = findLocationSearch(_hash, 'output');

    input && swapCoinModel.updateA(input);
    output && swapCoinModel.updateB(output);
  }, [hash[0]]);

  return (
    <>
      <SwapHeader />
      <div className={styles.wrapper}>
        <SwapSection
          label="From"
          coinType={coinTypeA}
          onSelectCoin={swapCoinModel.updateA}
          disableCoins={coinTypeB}
          showMaxAmount={true}
          amount={amountIn}
          inputByUser={amountSpecifiedIsInput}
          onChangeValue={handleSetInput}
          onChangeAmount={setAmount}
        />
        <div className={styles.price_row}>
          <PythPrice coinType={coinTypeA} />
          <CoinAPrice bestRoutes={bestRoute} amountIn={amountIn} className={styles.coin_price} />
        </div>

        <SwapExchange onExchange={handleExchange} />
        <SwapSection
          label="To"
          coinType={coinTypeB}
          onSelectCoin={swapCoinModel.updateB}
          disableCoins={coinTypeA}
          showMaxAmount={false}
          amount={amountOut}
          inputByUser={!amountSpecifiedIsInput}
          onChangeValue={handleSetOutput}
          onChangeAmount={setAmount}
        />
        <div className={styles.price_row}>
          <PythPrice coinType={coinTypeB} />
          <CoinBPrice
            bestRoutes={bestRoute}
            amountIn={amountIn}
            amountOut={amountOut}
            className={styles.coin_price}
            isAftermath={isAftermath}
            coinAType={coinTypeA}
          />
        </div>

        <SwapSubmit
          bestRoutes={bestRoute}
          fetchingBestRoute={fetchingBestRoute}
          amountSpecifiedIsInput={amountSpecifiedIsInput}
          insufficientLiquidity={insufficientLiquidity}
          amountIn={amountIn}
          amountOut={amountOut}
          onTransacted={handleTransacted}
          aftermathRoute={aftermathRoute}
          isAftermath={isAftermath}
        />
        <RouteInfo
          amountIn={amountIn}
          amountOut={amountOut}
          bestRoutes={bestRoute}
          isDeepbook={false}
          amountSpecifiedIsInput={amountSpecifiedIsInput}
          isAftermath={isAftermath}
          aftermathRoute={aftermathRoute}
        />
      </div>
    </>
  );
};

export default memo(Swap);
