import http from '@libs/http';
import { defineModel } from 'foca';

export interface CoinChanged24hItem {
  id: string;
  symbol: string;
  name: string;
  image: string;
  current_price: number;
  market_cap: number;
  market_cap_rank: number;
  fully_diluted_valuation: number;
  total_volume: number;
  high_24h: number;
  low_24h: number;
  price_change_24h: number;
  price_change_percentage_24h: number;
  market_cap_change_24h: number;
  market_cap_change_percentage_24h: number;
  circulating_supply: number;
  total_supply: number;
  max_supply: number | null;
  ath: number;
  ath_change_percentage: number;
  ath_date: string;
  atl: number;
  atl_change_percentage: number;
  atl_date: string;
  roi: null | {
    times: number;
    currency: string;
    percentage: number;
  };
  last_updated: string;
}

const initialState: CoinChanged24hItem[] = [];

export const coinChange24hModel = defineModel('coin-change-24h', {
  initialState,
  methods: {
    async fetch(coingeckoIds: string[]) {
      // DO NOT fetching this api very close, otherwise you will receive 429 status code.
      const result = await http.get<CoinChanged24hItem[]>(
        'https://api.coingecko.com/api/v3/coins/markets',
        {
          params: {
            vs_currency: 'usd',
            ids: coingeckoIds.join(','),
            order: 'market_cap_desc',
            per_page: 20,
            page: 1,
            sparkline: false,
            locale: 'en',
          },
        },
      );
      this.setState(result);
      return result;
    },
  },
});
