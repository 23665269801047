import { type FC, memo, type ReactElement, useState, useEffect } from 'react';
import { useToggle } from 'react-use';
import styles from './index.module.scss';
import hotIcon from '@assets/images/icons/hot.svg';
import hotActiveIcon from '@assets/images/icons/hot-active.svg';
import collapseIcon from '@assets/images/icons/collapse.svg';
import Tooltip from '@ui/tooltip';

export interface FixedFooterItem {
  key: string;
  label: string;
  element: ReactElement;
}

interface OwnProps {
  configs: FixedFooterItem[];
  defaultShowTab?: string;
  /**
   * Defaults `false`
   */
  defaultCollapsed?: boolean;
}

const FixedFooter: FC<OwnProps> = (props) => {
  const { configs, defaultShowTab, defaultCollapsed = false } = props;
  const [collapsed, toggleCollapsed] = useToggle(defaultCollapsed);
  const [tab, setTab] = useState(defaultShowTab || configs[0]?.key);

  useEffect(() => {
    const className = styles.has_fixed_footer!;
    document.body.classList.add(className);
    return () => document.body.classList.remove(className);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tab_header}>
        <ul>
          {configs.map((item) => {
            const isActive = item.key === tab;
            return (
              <li
                key={item.key}
                className={isActive ? styles.active : ''}
                onClick={() => {
                  setTab(item.key);
                  toggleCollapsed(false);
                }}
              >
                <img src={isActive ? hotActiveIcon : hotIcon} />
                {item.label}
              </li>
            );
          })}
        </ul>
        <div className={`${styles.collapse_button} ${collapsed ? '' : styles.active}`}>
          <Tooltip overlay={`${collapsed ? 'Show' : 'Hide'} LeaderBoard`} placement="right">
            <img onClick={toggleCollapsed} src={collapseIcon} />
          </Tooltip>
        </div>
      </div>
      {collapsed || (
        <div className={styles.tab_body}>{configs.find((item) => item.key === tab)?.element}</div>
      )}
    </div>
  );
};

export default memo(FixedFooter);
