import { Network } from 'turbos-clmm-sdk';

export interface DeepbookV3Config {
  TURBOS_DEEPBOOK_V3_PACKAGE_ID: string;
  TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG: string;
  TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX: string;
}

const devnet = {
  TURBOS_DEEPBOOK_V3_PACKAGE_ID:
    '0x87f825478a65fa6450e06339af696326cb5d0cf6b622ddf57a553a60aa18c3a6',
  TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG:
    '0x08afe4a94f835420b4531a6bb44f35c3dc496210ddcf387bde4688db66f6208a',
  TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX:
    '0x70dc7c20c2baa3d69429d7151195f8cc8f3c0603d60c3628d5b7f81e037b1f8e',
};

const testnet = {
  ...devnet,
};

const mainnet = {
  TURBOS_DEEPBOOK_V3_PACKAGE_ID:
    '0x4012c757777add200b2b74959a9f87e53fb908b4a56ff809e61fddb0b817483f',
  TURBOS_DEEPBOOK_V3_GLOBAL_CONFIG:
    '0x356a83d1fdb5fc4c74d3b7e7202370434cefb3b8c70b1ab9c62fb21923103ba1',
  TURBOS_DEEPBOOK_V3_BALANCE_MANAGER_INDEX:
    '0x93bdeb27171179ad8e1663cf656b4eaeda4927b47e158e92737a7dd37a39674d',
};

export const deepbookV3Config: Record<Network, DeepbookV3Config> = {
  devnet,
  testnet,
  mainnet,
};
