import { Network } from 'turbos-clmm-sdk';

const production = {
  sui_network: Network.mainnet,
  fun_api_url: 'https://api.turbos.finance/fun',
  api_url: 'https://api.turbos.finance',
  // api_url: 'http://54.234.151.46:6002',
  explorer_url: {
    suivision: {
      txb: 'https://suivision.xyz/txblock/{{txb}}',
      account: 'https://suivision.xyz/account/{{account}}',
      object: 'https://suivision.xyz/object/{{object}}',
      coin: 'https://suivision.xyz/coin/{{coin}}',
    },
    suiscan: {
      txb: 'https://suiscan.xyz/mainnet/tx/{{txb}}',
      account: 'https://suiscan.xyz/mainnet/account/{{account}}',
      object: 'https://suiscan.xyz/mainnet/object/{{object}}',
      coin: 'https://suiscan.xyz/mainnet/coin/{{coin}}',
    },
    sui_explorer: {
      txb: 'https://suiexplorer.com/txblock/{{txb}}',
      account: 'https://suiexplorer.com/address/{{account}}',
      object: 'https://suiexplorer.com/object/{{object}}',
      coin: 'https://suiexplorer.com/object/{{coin}}',
    },
  },
  rpc: <Record<string, string>>{
    suiscan: 'https://rpc-mainnet.suiscan.xyz/',
    sui_Official: 'https://fullnode.mainnet.sui.io:443',
    suiet: ' https://mainnet.suiet.app/',
    shinami: 'https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa',
  },
  // fullnode: 'https://sui-mainnet-endpoint.blockvision.org',
  // suinami node: https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa
  // fullnode: 'https://turbos-mainnet-endpoint.blockvision.org',
  fullnode: 'https://api.shinami.com/node/v1/sui_mainnet_bb70bc6a7d6d04694c4c719f0b6f27aa',
  // fullnode: 'https://fullnode.mainnet.sui.io:443',
  pyth_endpoint: 'https://hermes.pyth.network',
  deepbook_env: 'mainnet' as 'mainnet' | 'testnet',
  deepbook_package_id: '',
  burn_package_id: '0x89253eb9ca5bf424a118bfc1a172bbc2dd39d38becd00e5cbf24b87ee828bce5',
};

export type Config = typeof production;

export default production;
