import { config } from '@config';
import { type AxiosError, axios } from 'foca-axios';
import { toast } from 'react-toastify';

const http = axios.create({
  baseURL: config.api_url,
  timeout: 30000,
  cache: {
    enable: false,
  },
});

http.interceptors.request.use((cfg) => {
  if (cfg.baseURL === config.api_url) {
    cfg.headers ||= {};
    cfg.headers['api-version'] ||= 'v2';
  }
  return cfg;
});

http.interceptors.response.use(undefined, (err: AxiosError) => {
  if (axios.isCancel(err)) return;
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  toast(err.response?.data || err.message, { type: 'error' });
  throw err;
});

export default http;
