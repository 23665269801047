import { coinStatsModel } from '@models/coin-stats.model';
import { useLoading, useModel } from 'foca';
import { useEffect } from 'react';
import { useRefreshFlag } from './use-refresh';

export const useCoinStats = (hours: number) => {
  const stats = useModel(coinStatsModel, (state) => state[hours] || []);
  const loading = useLoading(coinStatsModel.getStats.room, hours);
  const { refresh } = useRefreshFlag();

  useEffect(() => {
    coinStatsModel.getStats.room(hours).execute(hours);
  }, [refresh, hours]);

  return { stats, loading };
};
