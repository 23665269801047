import { Network } from 'turbos-clmm-sdk';
import { type Config } from './mainnet';
import testnet from './testnet';

const development: Config = {
  ...testnet,
  sui_network: Network.devnet,
  fun_api_url: 'http://34.235.165.224:6012/',
  api_url: 'https://api.turbos.finance/devnet',
  // api_url: 'https://api.turbos.finance/testnet/',
  rpc: {
    sui_Official: 'https://fullnode.testnet.sui.io:443',
    shinami: 'https://api.shinami.com/node/v1/sui_testnet_60d3c675a6d403e634a0a04c2101a6bc',
  },
  burn_package_id: '0x3526c88f5304c78fb93ed1cc1961d56b8517108550c9938b8a5a0e6c90fbe2a5',
};

export default development;
