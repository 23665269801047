import { numberWithThousandSeparator } from '@utils/number-with-thousand-separator';
import Loading from '@ui/loading';
import styles from './trading-leaderboard.module.scss';
import { type FC, memo } from 'react';
import { useUserRanking } from '@hooks/use-user-ranking';
import { globalSettingModel } from '@models/global-setting.model';
import { useAddressDomain } from '@hooks/use-address-domain';
import { formatAddress } from '@utils/format-address';

const TradingLeaderboard: FC = () => {
  const { userRanking, userRankingLoading } = useUserRanking();

  const domains = useAddressDomain(...userRanking.map((item) => item.address));

  if (userRankingLoading && !userRanking.length) return <Loading size={42} centered />;

  return (
    <>
      {userRanking.map((item, index) => {
        return (
          <a
            href={globalSettingModel.explorerUrlForAccount(item.address)}
            target={'_blank'}
            className={styles.coin}
            key={item.address}
          >
            <span>#{index + 1}</span>
            <span>{formatAddress({ domain: domains[index], address: item.address })}</span>
            <span>${numberWithThousandSeparator(Number(item.trade_volume).toFixed(2))}</span>
          </a>
        );
      })}
    </>
  );
};

export default memo(TradingLeaderboard);
