import { turbosSdk } from '@libs/turbos-sdk';
import { BN } from 'turbos-clmm-sdk';
import { useCoin } from './use-coins';

export const useSqrtPriceToPrice = (
  sqrtPrice: string | undefined,
  coinTypeA: string | undefined,
  coinTypeB: string | undefined,
) => {
  const coinA = useCoin(coinTypeA);
  const coinB = useCoin(coinTypeB);

  return coinA && coinB && sqrtPrice
    ? turbosSdk.math
        .sqrtPriceX64ToPrice(new BN(sqrtPrice), coinA.decimals, coinB.decimals)
        .toString()
    : undefined;
};
