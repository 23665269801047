import { type FC, memo } from 'react';
import Tooltip from '@ui/tooltip';
import questionIcon from '@assets/images/icons/question.svg';
import { useModel } from 'foca';
import { globalSettingModel } from '@models/global-setting.model';

const SlippageTolerance: FC = () => {
  const { slippage } = useModel(globalSettingModel);

  return (
    <>
      <span>
        Slippage Tolerance&nbsp;
        <Tooltip
          onlyMessage
          placement="bottom"
          overlay="The amount the price can change unfavorably before the trade reverts"
        >
          <img src={questionIcon} data-icon />
        </Tooltip>
      </span>
      <span>
        {slippage}
        {slippage === 'Auto' ? '' : '%'}
      </span>
    </>
  );
};

export default memo(SlippageTolerance);
