import { parseGlobIcon } from '@utils/parse-glob-icon';

export enum SwapPriceStatus {
  great,
  fair,
  warn,
}

export const swapPriceTips: {
  [K in SwapPriceStatus]: string;
} = {
  [SwapPriceStatus.great]: 'Great price!',
  [SwapPriceStatus.fair]: 'Fair price!',
  [SwapPriceStatus.warn]: 'Rate warning!',
};

export const getSwapPriceIcon = parseGlobIcon(
  import.meta.glob('../assets/images/swap-price/*', {
    eager: true,
    import: 'default',
  }),
);
