import { Navigate, type RouteObject } from 'react-router-dom';
import App from '@components/app';
import type { FC } from 'react';
import Trade from '@pages/trade';
import DeepbookV2Swap from '@pages/deepbook-v2-swap';

const subRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/trade" />,
  },
  {
    path: '/trade',
    element: <Trade />,
  },
  {
    path: '/native-usdc',
    element: <DeepbookV2Swap />,
  },
  {
    path: '/limit',
    lazy: () => lazyLoad(import('@pages/limit')),
  },
  {
    path: '/pools',
    lazy: () => lazyLoad(import('@pages/pool')),
  },
  {
    path: '/pools/create',
    lazy: () => lazyLoad(import('@pages/create-pool')),
  },
  {
    path: '/pools/:poolId/add-liquidity',
    lazy: () => lazyLoad(import('@pages/pool-add-liquidity')),
  },
  {
    path: '/pools/:nftId/position',
    lazy: () => lazyLoad(import('@pages/position')),
  },
  {
    path: '/bridge',
    lazy: async () => lazyLoad(import('@pages/bridge')),
  },
  {
    path: '/prix',
    lazy: async () => lazyLoad(import('@pages/prix')),
  },
  {
    path: '/stats',
    lazy: async () => lazyLoad(import('@pages/info')),
  },
  {
    path: '/account/transaction',
    lazy: async () => lazyLoad(import('@pages/account-transaction')),
  },
  {
    path: '/yield-strategy',
    lazy: async () => lazyLoad(import('@pages/yield-strategy')),
  },
  {
    path: '/yield-strategy/:strategyId/create-vault',
    lazy: async () => lazyLoad(import('@pages/yield-strategy/create-vault')),
  },
  {
    path: '/yield-strategy/:nftId/my-vault',
    lazy: async () => lazyLoad(import('@pages/yield-strategy/my-vault')),
  },
  {
    path: '/referral-link',
    lazy: async () => lazyLoad(import('@pages/yield-strategy/referral-link')),
  },
  {
    path: '/lp-lock',
    lazy: async () => lazyLoad(import('@pages/lp-burn')),
  },
  {
    path: '/fun',
    lazy: async () => lazyLoad(import('@pages/launch')),
  },
];

export const routerObjects: RouteObject[] = [
  {
    element: <App />,
    children: subRoutes,
  },
];

// if (import.meta.env.MODE !== 'mainnet') {
//   subRoutes.push({
//     path: '/faucet',
//     lazy: () => lazyLoad(import('@pages/faucet')),
//   });
// }

const lazyLoad = async (dynamicImport: Promise<{ default: FC }>) => {
  const obj = await dynamicImport;
  return {
    Component: obj.default,
  };
};
