import { config } from '@config';
import { defineModel } from 'foca';
import { Network } from 'turbos-clmm-sdk';

const initialState: {
  coinTypeA: string;
  coinTypeB: string;
} = {
  coinTypeA: '0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN',
  coinTypeB:
    config.sui_network === Network.mainnet
      ? '0xdba34672e30cb065b1f93e3ab55318768fd6fef66c15942c9f7cb846e2f900e7::usdc::USDC'
      : '',
};

export const nativeCoinModel = defineModel('native-coin', {
  initialState,
  reducers: {
    updateA(state, coinType: string) {
      state.coinTypeA = coinType;
    },
    updateB(state, coinType: string) {
      state.coinTypeB = coinType;
    },
    exchange(state) {
      return {
        coinTypeA: state.coinTypeB,
        coinTypeB: state.coinTypeA,
      };
    },
  },
  events: {},
});
