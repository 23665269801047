import { normalizeStructTag, SUI_TYPE_ARG } from '@mysten/sui/utils';
import { useState, useEffect } from 'react';

interface PriceInfo {
  price: number;
  priceChange24HoursPercentage: number;
}

export function useAftermathPrice(type?: string) {
  const [price, setPrice] = useState<number | null>(null);

  useEffect(() => {
    if (!type) return;

    if (type === SUI_TYPE_ARG) {
      type = normalizeStructTag(type);
    }

    const fetchPrice = async () => {
      try {
        const response = await fetch(`https://aftermath.finance/api/price-info/["${type}"]`);
        const data = (await response.json()) as Record<string, PriceInfo | undefined>;

        if (type && type in data && data[type] && typeof data[type]?.price === 'number') {
          setPrice(data[type]!.price);
        } else {
          setPrice(null);
        }
      } catch (_error) {
        setPrice(null);
      }
    };

    fetchPrice();

    const intervalId = setInterval(fetchPrice, 2000);

    return () => clearInterval(intervalId);
  }, [type]);

  return price;
}
