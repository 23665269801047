import { parseGlobIcon } from '@utils/parse-glob-icon';

export interface Menus {
  path: string;
  name: string;
  key: string;
  target?: string;
  visible?: boolean;
  children?: Menus[];
}

export const menus: Menus[] = [
  {
    path: '/trade',
    name: 'Trade',
    key: 'trade',
    children: [
      {
        path: '/trade',
        name: 'Swap',
        key: 'children-swap',
      },
      {
        path: '/limit',
        name: 'Limit Order',
        key: 'children-limit',
      },
    ],
  },
  // {
  //   path: '/fun',
  //   name: 'Fun',
  //   key: 'fun',
  // },
  {
    path: '/pools',
    name: 'Earn',
    key: 'pool',
  },
  {
    path: '/yield-strategy',
    name: 'Yield Strategy',
    key: 'yieldStrategy',
  },
  // {
  //   path: 'https://app.turbos.finance/turbostar',
  //   name: 'TurboStar',
  //   key: 'turbostar',
  // },
  {
    path: '/bridge',
    name: 'Bridge',
    key: 'bridge',
    visible: true,
    children: [
      {
        path: 'https://bridge.sui.io/',
        name: 'Sui Bridge',
        key: 'sui-bridge',
        target: '_blank',
      },
      {
        path: '/bridge',
        name: 'Wormhole',
        key: 'wormhole',
        target: '_blank',
      },
    ],
  },
  {
    path: '/stats',
    name: 'Stats',
    key: 'stats',
  },
  {
    path: '/prix',
    name: 'Grand Prix',
    key: 'prix',
  },
];

// if (import.meta.env.MODE !== 'mainnet') {
//   menus.push({
//     path: '/faucet',
//     key: 'faucet',
//     name: 'Faucet',
//   });
// }

export const getHeaderMenuIcon = parseGlobIcon(
  import.meta.glob('../assets/images/menus/*', {
    eager: true,
    import: 'default',
  }),
);
