import { memo, type FC } from 'react';
import swapIcon from '@assets/images/icons/swap.svg';
import { swapCoinModel } from '@models/swap-coin.model';
import styles from './swap-exchange.module.scss';
import { useModel } from 'foca';

interface OwnProps {
  onExchange(): void;
}

export const SwapExchange: FC<OwnProps> = (props) => {
  const { onExchange } = props;
  const hasCoin = useModel(swapCoinModel, (state) => !!state.coinTypeA && !!state.coinTypeB);

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.button} ${hasCoin ? '' : styles.not_allowed}`}
        onClick={hasCoin ? onExchange : undefined}
      >
        <img src={swapIcon} />
      </div>
    </div>
  );
};

export default memo(SwapExchange);
