import { defineModel } from 'foca';
import { type TurbosCoinItem } from './coin.model';
import defaultCoinIcon from '@assets/images/icons/default-coin.svg';

const initialState: TurbosCoinItem[] = [];

export const localCoinModel = defineModel('local-coin', {
  initialState,
  reducers: {
    append(state, coin: TurbosCoinItem) {
      state.push({
        ...coin,
        logo_url: coin.logo_url || defaultCoinIcon,
      });
    },
  },
});
