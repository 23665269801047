import { Network } from 'turbos-clmm-sdk';
import production, { type Config } from './mainnet';

const staging: Config = {
  ...production,
  sui_network: Network.testnet,
  deepbook_env: 'testnet',
  api_url: 'https://api.turbos.finance/testnet/',
  explorer_url: {
    suiscan: {
      txb: 'https://suiscan.xyz/testnet/tx/{{txb}}',
      account: 'https://suiscan.xyz/testnet/account/{{account}}',
      object: 'https://suiscan.xyz/testnet/object/{{object}}',
      coin: 'https://suiscan.xyz/testnet/coin/{{coin}}',
    },
    suivision: {
      txb: 'https://testnet.suivision.xyz/txblock/{{txb}}',
      account: 'https://testnet.suivision.xyz/account/{{account}}',
      object: 'https://testnet.suivision.xyz/object/{{object}}',
      coin: 'https://testnet.suivision.xyz/coin/{{coin}}',
    },
    sui_explorer: {
      txb: 'https://suiexplorer.com/txblock/{{txb}}?network=testnet',
      account: 'https://suiexplorer.com/address/{{account}}?network=testnet',
      object: 'https://suiexplorer.com/object/{{object}}?network=testnet',
      coin: 'https://suiexplorer.com/object/{{coin}}?network=testnet',
    },
  },
  fullnode: 'https://fullnode.testnet.sui.io:443',
  deepbook_package_id: '0x2c152dba0110d3afb76b659ed3436edd848b37e177c3abfc0296f8aefc2e6cf4',
};

export default staging;
