import { type FC, memo } from 'react';
import styles from './swap-header.module.scss';
import { useRefreshIcon } from '@hooks/use-refresh';

import { useNavigate } from 'react-router-dom';

const SwapHeader: FC = () => {
  const RefreshView = useRefreshIcon();
  const navigator = useNavigate();

  const link = () => {
    navigator('/limit');
  };

  return (
    <div className={styles.header}>
      <div className={styles.tab}>
        <div className={styles.active}>Swap</div>
        <div onClick={link}>Limit</div>
      </div>
      <div className={styles.refresh_view}>{RefreshView}</div>
    </div>
  );
};

export default memo(SwapHeader);
