import { priceModel } from '@models/price.model';
import { useModel } from 'foca';
import { useEffect } from 'react';
import { useRefreshFlag } from './use-refresh';

export const usePrice = (coinType: string) => {
  const { refresh } = useRefreshFlag();
  const price = useModel(priceModel);

  useEffect(() => {
    if (!coinType) return;
    priceModel.fetchPrice(coinType);
  }, [refresh, coinType]);

  return price[coinType];
};
