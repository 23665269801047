import { privacyModel } from '@models/privacy.model';
import Dialog from '@ui/dialog';
import { useModel } from 'foca';
import { type FC, memo, useCallback } from 'react';
import privacyImg from '@assets/images/pictures/privacy.png';
import checkboxIcon from '@assets/images/icons/checkbox.svg';
import checkboxSelectedIcon from '@assets/images/icons/checkbox-selected.svg';

import styles from './index.module.scss';
import Button from '@ui/button';
import { useToggle } from 'react-use';

const Privacy: FC = () => {
  const privacy = useModel(privacyModel);
  const [selected, setSelected] = useToggle(false);

  const handleAgree = useCallback(() => {
    privacyModel.agree();
  }, []);

  return (
    <Dialog
      visible={!privacy.agree}
      closable={false}
      maskClosable={false}
      title="Terms of Use Agreement"
      className={styles.dialog}
    >
      <div className={styles.img_wrapper}>
        <img src={privacyImg} />
      </div>
      <p>
        Please read and agree to the&nbsp;
        <a target="_blank" href="./privacy.html">
          Terms of Use Agreement
        </a>
      </p>
      <div className={styles.checkbox}>
        <img src={selected ? checkboxSelectedIcon : checkboxIcon} onClick={setSelected} />
        <label className={selected ? styles.selected : ''} onClick={setSelected}>
          &nbsp;I accept the <span>Terms of Use Agreement</span>
        </label>
      </div>
      <Button
        disabled={!selected}
        type={selected ? 'highlight' : 'normal'}
        fullWidth
        size="large"
        onClick={handleAgree}
      >
        Continue
      </Button>
    </Dialog>
  );
};

export default memo(Privacy);
