import type { FC } from 'react';
import hotIcon from '@assets/images/icons/hot-token-coin.svg';
import Tooltip from '@ui/tooltip';

export const HotTokenIcon: FC<{ hot: boolean; className?: string; tooltip?: boolean }> = ({
  hot,
  className = '',
  tooltip = true,
}) => {
  if (!hot) return null;
  return (
    <Tooltip overlay="Hot Token" onlyMessage placement="top" visible={tooltip ? undefined : false}>
      <img src={hotIcon} data-icon className={className} />
    </Tooltip>
  );
};
