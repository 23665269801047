import http from '@libs/http';
import { defineModel } from 'foca';

export interface TurboCoinStatsItem {
  coin_symbol: string;
  coin_type: string;
  _sum: {
    fee: number;
    liquidity: number;
    trade_amount: number;
    volume: number;
  };
}

const initialState: {
  [hour: string]: TurboCoinStatsItem[];
} = {};

export const coinStatsModel = defineModel('coin-stats', {
  initialState,
  methods: {
    async getStats(hours: number) {
      const result = await http.get<TurboCoinStatsItem[]>('/coins/stats', {
        params: { hours },
      });
      this.setState({ [hours]: result });
      return result;
    },
  },
});
