import { useAftermathPrice } from '@hooks/use-aftermath-price';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import { type FC, memo } from 'react';
import { Decimal } from 'turbos-clmm-sdk';

interface OwnProps {
  fee: {
    type: string;
    symbol: string;
    totalFee: string;
    feePrice: string;
    decimals: number;
  };
}

const AftermathTradeFeesItem: FC<OwnProps> = ({ fee }) => {
  const price = useAftermathPrice(fee.type);

  if (!price) return null;

  const feePrice = new Decimal(fee.totalFee)
    .div(10 ** fee.decimals)
    .mul(price)
    .toString();

  return (
    <div>
      <span>
        {fee.totalFee}&nbsp;
        {fee.symbol}
      </span>
      <span>&nbsp;({feePrice ? `~\$${bigNumberToReadable(feePrice, 0)}` : '-'})</span>
    </div>
  );
};

export default memo(AftermathTradeFeesItem);
