import { useMemo } from 'react';
import type { BestSwapRouterType } from './use-swap-route';
import { Decimal } from 'turbos-clmm-sdk';

export const useSwapInsufficientLiquidity = (
  bestRoutes: BestSwapRouterType[] | undefined,
  amountIn: string | undefined,
  amountOut: string | undefined,
): boolean => {
  return useMemo(() => {
    if (!bestRoutes) return false;
    if (!bestRoutes.length || amountIn === '0' || amountOut === '0') return true;

    return bestRoutes.some((route) => {
      if (!route.pool) {
        return new Decimal(0);
      }
      const totalInPool = route.pool.a_to_b ? route.pool.coin_b : route.pool.coin_a;
      return new Decimal(totalInPool).lt(route.amountOut);
    });
  }, [bestRoutes, amountIn, amountOut]);
};
